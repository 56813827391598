import React, { useEffect, useMemo } from 'react';
import _sumBy from 'lodash/sumBy';
import { Box, Flex, Spacer } from '@chakra-ui/react';
import numeral from 'numeral';

import CRUD, { useCRUD, Table, NumberFormat } from 'Library/CRUD';
import { useAuth, useDB } from 'store';
import { Text, Divider, Icon, Alert } from 'components';
import { useAlert } from 'utils';

interface IPendingSettlementProps {
  amount: number;
  setAmount: (n: number) => void;
}

const PendingSettlement = (props: IPendingSettlementProps) => {
  const { setAmount, amount } = props;
  const user = useAuth((state) => state.user);

  const [alert, setAlert] = useAlert();

  const crud = useCRUD({
    id: 'transaction',
    title: user.Mode === 'API' ? '' : 'Transactions',
    hasEditor: false,
    keyField: 'id',
    setAlert: setAlert,
    waitRefresh: true,
    filter: {
      merchant: user.Merchant,
    },
    fetch: async (qry: string) => {
      // if (!filter.query) return [] //Wait until there is a filter
      let result = await useDB.getState().axios({
        server: 'PAYGATE',
        method: 'GET',
        url: `/api/v1/dashboard/pending${qry}`,
      });
      let total = _sumBy(result.data.data, (rec: any) => parseFloat(rec.Amount));
      setAmount(total);
      return result.data.data;
    },
  });

  useEffect(() => {
    crud.refresh({
      message: 'CHANGE USER ID',
      filter: { merchant: user.Merchant },
    });
    // eslint-disable-next-line
  }, [user.Merchant]);

  const columns = useMemo(
    () => [
      {
        name: 'Card Type',
        selector: (row: any) => row['CardTypeName'],
        sortable: true,
      },
      {
        name: 'Transaction Type',
        selector: (row: any) => row['TransactionType'],
        format: (row: any) => <Text>{row.TransactionType}</Text>,
        sortable: true,
        width: '145px',
      },
      {
        name: 'Amount',
        selector: (row: any) => row['Amount'],
        sortable: false,
        format: (row: any) =>
          row.TransactionType === 'REFUND' ? (
            <NumberFormat color={'red'} amount={row.Amount} />
          ) : (
            <NumberFormat amount={row.Amount} />
          ),
        right: true,
        width: '75px',
      },
    ],
    [],
  );

  return (
    <Flex
      borderRadius={'4px'}
      border={'1px solid #F2F3F3'}
      bg={'white'}
      p={'24px'}
      flexDir={'column'}
      height={'100%'}
      overflow={'auto'}
    >
      <Flex h={'44px'}>
        <Icon variant={'PendingSettlement'} size={11} />
        <Flex flexDir={'column'} ml={'12px'}>
          <Text variant={'heading05Bold'} color={'secondary.800'} mb={'4px'}>
            Pending Settlement
          </Text>
          <Spacer />
          <Text variant={'labelSmallRegular'} color={'secondary.700'}>
            {numeral(amount).format('$0,00.00')}
          </Text>
        </Flex>
      </Flex>
      <Divider my={'16px'} variant={'fade'} />
      <Box fontFamily={'Roboto Condensed'} overflow={'hidden'}>
        <CRUD
          id="transactions"
          crud={crud}
          title={
            <Text color={'secondary.700'} fontFamily={'Public Sans'} variant={'labelSmallBold'}>
              Transactions
            </Text>
          }
        >
          <Alert alert={alert} setAlert={setAlert} />
          <Table
            pagination={false}
            id="bank_table"
            crud={crud}
            columns={columns}
            responsive={true}
            striped={false}
          ></Table>
        </CRUD>
      </Box>
    </Flex>
  );
};

export default React.memo(PendingSettlement);
