import { useState, useCallback } from 'react';

type TPasswordType = 'text' | 'password';
export const usePasswordType = (defaultPasswordType?: TPasswordType) => {
  const [passwordType, setPasswordType] = useState<string>(defaultPasswordType || 'password');

  const togglePasswordType = useCallback(() => {
    setPasswordType((passwordType) => (passwordType === 'password' ? 'text' : 'password'));
  }, [setPasswordType]);

  return { passwordType, setPasswordType, togglePasswordType };
};
