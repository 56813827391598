import React from 'react';
import { forwardRef, MenuButton, MenuButtonProps } from '@chakra-ui/react';

import { Button, Icon } from 'components';

const CustomButton = forwardRef((props, ref) => {
  const { caretDir = 'downToUp', children, isIconOnly, ...rest } = props;
  const isExpanded = props['aria-expanded'];

  const caretDirMap: { [key: string]: number } = {
    rightToDown: isExpanded ? 0 : -90,
    downToUp: isExpanded ? -180 : 0,
    downToRight: isExpanded ? -90 : 0,
  };

  const iconOnlyStyles = isIconOnly ? { minWidth: '32px', width: '32px', p: 0 } : {};

  return (
    <Button
      ref={ref}
      rightIcon={
        !isIconOnly && !rest.rightIcon ? (
          <Icon
            variant="CaretDown"
            transform={`rotate(${caretDirMap[caretDir]}deg)`}
            transition="all 0.2s"
          />
        ) : rest.rightIcon ? (
          rest.rightIcon
        ) : undefined
      }
      {...iconOnlyStyles}
      {...rest}
    >
      {isIconOnly ? (
        <Icon
          variant="CaretDown"
          transform={`rotate(${caretDirMap[caretDir]}deg)`}
          transition="all 0.2s"
          marginInlineStart={'0px'}
        />
      ) : (
        children
      )}
    </Button>
  );
});

interface Props extends MenuButtonProps {
  caretDir?: string;
  isIconOnly?: boolean;
}

const DropdownButton = forwardRef<Props, React.FC>((props, ref) => {
  const { children, ...rest } = props;

  return (
    <MenuButton ref={ref} as={CustomButton} {...rest}>
      {children}
    </MenuButton>
  );
});

export default DropdownButton;
