import { ChakraTheme, ThemeComponentProps } from '@chakra-ui/react';
import { getColor } from '@chakra-ui/theme-tools';

const parts = [
  'card',
  'head',
  'headInner',
  'title',
  'description',
  'leftActions',
  'rightActions',
  'leftButton',
  'rightButton',
];

const baseStyle = (props: ThemeComponentProps) => {
  const { theme, colorScheme, isBordered, isHeaderBordered, isActive } = props;
  const borderColor = colorScheme === 'white' ? 'gray.400' : `${colorScheme}.500`;

  return {
    card: {
      pos: 'relative',
      bg: '#fff',
      borderRadius: '4px',
      boxShadow: isBordered
        ? `0px 0px 0px 1px ${getColor(theme, borderColor)}`
        : '0px 2px 4px rgba(0, 0, 0, 0.08)',
      ...(isActive
        ? {
            '::before': {
              content: `''`,
              pos: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '4px',
              borderTopLeftRadius: '4px',
              borderTopRightRadius: '4px',
              bg: borderColor,
            },
          }
        : {}),
    },
    head: {
      display: 'flex',
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
      ...(isHeaderBordered ? { borderBottom: '1px solid', borderBottomColor: 'gray.400' } : {}),
    },
    headInner: {
      flex: 1,
    },
    title: {
      d: 'flex',
      alignItems: 'center',
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: 'bold',
    },
    description: {
      mt: '4px',
      fontSize: '14px',
      lineHeight: '20px',
      color: 'gray.700',
    },
    body: {
      flexGrow: 1,
      lineHeight: 1.5,
      _empty: {
        display: 'none',
      },
    },
    leftActions: {
      flexShrink: 0,
    },
    rightActions: {
      ml: 'auto',
      flexShrink: 0,
    },
    leftButton: {
      d: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: 'auto',
      borderTopLeftRadius: '4px',
      borderBottomLeftRadius: '4px',
    },
    rightButton: {
      d: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: 'auto',
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px',
    },
  };
};

const variants = {
  default: (props: ThemeComponentProps) => {
    const { colorScheme } = props;
    const bg = colorScheme === 'white' ? '#fff' : `${colorScheme}.100`;

    return {
      card: {
        bg,
      },
    };
  },
  header: (props: ThemeComponentProps) => {
    const { colorScheme } = props;
    const borderBottomColor = colorScheme === 'white' ? 'gray.400' : `${colorScheme}.500`;

    return {
      headInner: {
        borderBottom: 'solid 1px',
        borderBottomColor,
      },
    };
  },
  summary: (props: ThemeComponentProps) => {
    const { colorScheme } = props;
    const borderBottomColor = colorScheme === 'white' ? 'gray.400' : `${colorScheme}.500`;
    const bg = colorScheme === 'white' ? 'gray.200' : `${colorScheme}.100`;

    return {
      head: {
        ml: 0,
        mr: 0,
        borderBottom: 'solid 1px',
        borderBottomColor: borderBottomColor,
        bg,
      },
    };
  },
};

const getSizes = (props: ThemeComponentProps<ChakraTheme>, size: 'sm' | 'md' | 'lg') => {
  const { variant, space, hasHead, hasLeftButton, hasRightButton } = props;
  const gutter = space || { sm: '16px', md: '24px', lg: '32px' }[size];

  return {
    head: {
      ...(variant === 'summary'
        ? { px: gutter }
        : { ml: hasLeftButton ? 0 : gutter, mr: hasRightButton ? 0 : gutter }),
    },
    headInner: {
      py: gutter,
      pl: hasLeftButton ? `calc(${gutter} / 2)` : 0,
      pr: hasRightButton ? `calc(${gutter} / 2)` : 0,
    },
    body: {
      pt: variant === 'header' || variant === 'summary' ? gutter : hasHead ? 0 : gutter,
      pb: gutter,
      px: gutter,
    },
    leftActions: {
      pr: gutter,
    },
    rightActions: {
      pl: gutter,
    },
    leftButton: {
      pl: gutter,
      pr: `calc(${gutter} / 2)`,
      fontSize: '24px',
      color: 'gray.600',
      transition: 'all 0.2s',

      svg: {
        width: '1em',
        height: '1em',
        transition: 'all 0.2s',
      },
    },
    rightButton: {
      pr: gutter,
      pl: `calc(${gutter} / 2)`,
      fontSize: '24px',
      color: 'gray.700',
      transition: 'all 0.2s',

      svg: {
        width: '1em',
        height: '1em',
        transition: 'all 0.2s',
      },
    },
  };
};

const sizes = {
  sm: (props: ThemeComponentProps<ChakraTheme>) => {
    return {
      ...getSizes(props, 'sm'),
      title: {
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '20px',
      },
    };
  },
  md: (props: ThemeComponentProps<ChakraTheme>) => getSizes(props, 'md'),
  lg: (props: ThemeComponentProps<ChakraTheme>) => getSizes(props, 'lg'),
};

const defaultProps = {
  variant: 'default',
  size: 'md',
  colorScheme: 'white',
};

export const cardTheme = {
  parts,
  baseStyle,
  variants,
  sizes,
  defaultProps,
};
