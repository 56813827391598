import React, { useEffect } from 'react';
import { Grid, Input as ChakraInput, InputGroup, GridItem, Checkbox } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

import { Form, Input, InputControl, Select } from 'Library/CRUD';
import { getSelect } from 'Library';
import { useStore } from 'store';

interface IMerchantEdit {
  id: string;
  crud: any;
}

export const MerchantEdit = React.memo((props: IMerchantEdit) => {
  let { id, crud } = props;
  let form = useForm();
  let { provider, merchant_account, token_expiry_seconds, rateplan, inactive } = form.watch();

  //APPLY CRUD RECORD VALUES TO FORM
  useEffect(() => {
    if (crud && crud.record && Object.keys(crud.record).length) {
      form.reset(crud.record);
    }
    // Override es-lint (We do not want to update everytime other parts of Crud Change)
    // eslint-disable-next-line
  }, [crud.record]);

  const lookup = useStore((state) => state.lookup);
  const providerSelect = getSelect(lookup.Provider, {
    keyField: 'code',
    label: 'name',
  });
  const rateplanSelect = getSelect(lookup.Rateplan, {
    keyField: 'code',
    label: 'name',
  });

  const onSubmit = async () => {
    // e.preventDefaullt()
    let result = await crud.submit(form.getValues());
    if (result && !result.error) crud.set({ active: false });
  };

  return (
    <Form minHeight={550} form={form} crud={crud} id={id} onSubmit={form.handleSubmit(onSubmit)}>
      <Grid gap="8px" templateColumns={{ base: '1fr', lg: '1fr 1fr' }}>
        <GridItem>
          <Input
            form={form}
            name="code"
            label="Code"
            variant="input"
            size="lg"
            readOnly={crud.key}
            placeholder="Enter Merchant Code"
            sx={{ textTransform: 'uppercase' }}
            validation={{
              required: 'Merchant Code is Required',
            }}
          />
        </GridItem>
        <GridItem>
          <Input
            form={form}
            name="gp_serial_no"
            label="GP Serial No"
            variant="input"
            size="lg"
            readOnly={crud.key}
            placeholder="Enter Guestpoint Serial No"
            sx={{ textTransform: 'uppercase' }}
          />
        </GridItem>
        <GridItem colSpan={2}>
          <Input
            form={form}
            name="name"
            label="Name"
            variant="input"
            size="lg"
            placeholder="Enter Merchant Name"
            validation={{
              required: 'Merchant Name is Required',
            }}
          />
        </GridItem>
        <GridItem>
          <InputControl
            id="provider"
            form={form}
            label="Provider"
            validation={{
              required: 'Provider is Required',
            }}
            control={
              <InputGroup size="lg">
                <Select
                  name="provider"
                  defaultValue={provider}
                  options={providerSelect}
                  onChange={(val: any) => {
                    form.setValue('provider', val);
                  }}
                  size="lg"
                  placeholder="Select Provider..."
                />
              </InputGroup>
            }
          />
        </GridItem>
        <GridItem>
          <Input
            form={form}
            name="merchant_account"
            label="Providers Account ID"
            variant="input"
            size="lg"
            value={merchant_account}
            placeholder="Enter Merchant Name"
            validation={{
              required: 'Provider Account ID is required',
            }}
          />
        </GridItem>
        <GridItem>
          <InputControl
            id="rateplan"
            form={form}
            label="Rate Plan"
            validation={{
              required: 'Rate Plan is Required',
            }}
            control={
              <InputGroup size="lg">
                <Select
                  name="rateplan"
                  defaultValue={rateplan}
                  options={rateplanSelect}
                  onChange={(val: any) => {
                    form.setValue('rateplan', val);
                  }}
                  size="lg"
                  placeholder="Select Rate Plan..."
                />
              </InputGroup>
            }
          />
        </GridItem>
        <GridItem>
          {/* NOTE - THIS LEFT HERE AS EXAMPLE OF NOT USING SIMPIFIED INPUT CONTROL */}
          <InputControl
            id="token_expiry_seconds"
            form={form}
            label="Card Entry Token Expiry (seconds)"
            control={
              <InputGroup size="lg">
                <ChakraInput
                  name="token_expiry_seconds"
                  defaultValue={token_expiry_seconds}
                  onChange={(e) => form.setValue('token_expiry_seconds', e.target.value)}
                  size="lg"
                  placeholder="Enter Token Expiry time (card entry) in seconds"
                />
              </InputGroup>
            }
          />
        </GridItem>
        <GridItem />
        <GridItem colSpan={2}>
          <Input
            form={form}
            name="merchant_api_key"
            label="Merchant Specific API Key"
            variant="input"
            size="lg"
            placeholder="Merchant API Key"
          />
        </GridItem>
        <GridItem colSpan={2}>
          <Input
            form={form}
            name="merchant_username"
            label="Auth User Name (if applicable)"
            variant="input"
            size="lg"
            placeholder="Merchant User Name"
          />
        </GridItem>
        <GridItem colSpan={2}>
          <Input
            form={form}
            name="merchant_password"
            label="Auth Password (if applicable)"
            variant="input"
            size="lg"
            placeholder="Merchant Password"
          />
        </GridItem>

        <GridItem colSpan={2}>
          <Checkbox
            isChecked={inactive}
            size="lg"
            onChange={(e) => {
              form.setValue('inactive', e.target.checked);
            }}
          >
            Set as Inactive
          </Checkbox>
        </GridItem>
      </Grid>
    </Form>
  );
});
