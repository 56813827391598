import { getColor } from '@chakra-ui/theme-tools';
import { SystemStyleObject, ThemeThunk } from '@chakra-ui/styled-system';

export interface SelectSizes {
  xs: ThemeThunk<SystemStyleObject>;
  sm: ThemeThunk<SystemStyleObject>;
  md: ThemeThunk<SystemStyleObject>;
  lg: ThemeThunk<SystemStyleObject>;
}

type Options<T extends string> = Record<T, ThemeThunk<SystemStyleObject>>;
interface SelectTheme {
  baseStyle: ThemeThunk<SystemStyleObject>;
  sizes: Options<keyof SelectSizes>;
  defaultProps: { variant: string; size: keyof SelectSizes };
}

export const selectTheme: SelectTheme = {
  baseStyle: (props) => {
    const { theme, isDisabled } = props;
    const boxShadowColor = getColor(theme, 'blue.300');

    return {
      field: {
        lineHeight: 1,
        background: 'gray.100',
        backgroundColor: 'gray.100',
        border: '1px solid',
        borderColor: 'gray.500',
        borderRadius: '4px',

        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',

        fontWeight: 500,
        color: 'gray.500',
        transition: 'box-shadow 250ms', // from all 250ms, to remove color transition
        _selected: {
          color: 'gray.800',
        },

        '& > option': {
          color: 'gray.800',
        },
        '& > option:first-of-type': {
          color: 'gray.500',
        },

        _invalid: {
          borderColor: 'red.500',
          boxShadow: 'none',
          _disabled: {
            backgroundColor: 'gray.400',
            color: 'gray.500',
            borderColor: 'gray.500',
            opacity: 1,
          },
        },
        _focus: {
          borderColor: 'blue.500',
          boxShadow: `0 0 0 1px #fff, 0 0 0 3px ${boxShadowColor}`,
          _disabled: {
            backgroundColor: 'gray.400',
            color: 'gray.500',
            borderColor: 'gray.500',
            opacity: 1,
          },
        },
        _disabled: {
          backgroundColor: 'gray.400',
          color: 'gray.500',
          opacity: 1,
          cursor: 'not-allowed',
        },
      },
      icon: {
        color: 'gray.700',
        _disabled: {
          opacity: isDisabled ? 0.5 : 1,
        },
        opacity: 1,
      },
    };
  },
  sizes: {
    xs: {
      field: {
        lineHeight: '16px',
        fontSize: '12px',
        padding: '2px 4px',
        paddingRight: '24px',
        h: '22px',
      },
    },
    sm: {
      field: {
        lineHeight: '22px',
        fontSize: '14px',
        padding: '4px 8px',
        paddingRight: '24px',
        h: '30px',
      },
    },
    md: {
      field: {
        lineHeight: '22px',
        fontSize: '14px',
        padding: '8px 12px',
        paddingRight: '24px',
        height: '38px',
      },
    },
    lg: {
      field: {
        lineHeight: '30px',
        fontSize: '16px',
        padding: '8px 12px',
        paddingRight: '36px',
        h: '46px',
      },
    },
  },
  defaultProps: {
    variant: 'unstyled',
    size: 'md',
  },
};
