import { SystemStyleObject, ThemeThunk } from '@chakra-ui/styled-system';
import { getColor, transparentize } from '@chakra-ui/theme-tools';

export interface ButtonVariants {
  solid: ThemeThunk<SystemStyleObject>;
  outline: ThemeThunk<SystemStyleObject>;
  inverted: ThemeThunk<SystemStyleObject>;
  transparent: ThemeThunk<SystemStyleObject>;
}

export interface ButtonSizes {
  xxs: ThemeThunk<SystemStyleObject>;
  xs: ThemeThunk<SystemStyleObject>;
  sm: ThemeThunk<SystemStyleObject>;
  md: ThemeThunk<SystemStyleObject>;
  lg: ThemeThunk<SystemStyleObject>;
}

type Options<T extends string> = Record<T, ThemeThunk<SystemStyleObject>>;
interface ButtonTheme {
  baseStyle: ThemeThunk<SystemStyleObject>;
  variants: Options<keyof ButtonVariants>;
  sizes: Options<keyof ButtonSizes>;
  defaultProps: {
    size: string;
    variant: string;
    colorScheme: string;
  };
}

const buttonTheme: ButtonTheme = {
  baseStyle: (props) => {
    const { theme } = props;
    const boxShadowColor = getColor(theme, 'blue.300');

    return {
      lineHeight: 1.2,
      border: '1px solid transparent',
      borderRadius: '4px',
      boxSizing: 'border-box',

      _focus: {
        outline: 0,
        boxShadow: 0,
      },

      _hover: {
        _disabled: {
          opacity: 1,
        },
      },

      _disabled: {
        opacity: 1,
      },

      _before: {
        content: `""`,
        width: 'calc(100% + 4px)',
        height: 'calc(100% + 4px)',
        position: 'absolute',
        bg: 'transparent',
        borderRadius: '4px',
        zIndex: 1,
      },

      ':focus-within::before': {
        boxShadow: `0 0 0 3px ${boxShadowColor}`,
      },
    };
  },
  sizes: {
    xxs: {
      height: '16px',
      padding: '0px 2px',
      fontSize: '14px',
      lineHeight: '16px',
    },
    xs: {
      height: '24px',
      padding: '4px 8px 4px 8px',
      fontSize: '12px',
    },
    sm: {
      height: '32px',
      padding: '8px 12px 8px 12px',
      fontSize: '14px',
    },
    md: {
      height: '40px',
      padding: '12px',
      fontSize: '14px',
    },
    lg: {
      height: '48px',
      padding: '12px 16px 12px 16px',
      fontSize: '16px',
    },
  },
  variants: {
    solid: (props) => {
      const { colorScheme: c, theme } = props;
      let bg = getColor(theme, `${c}.500`);
      let bgHover = getColor(theme, `${c}.600`);
      let color = 'white';
      let colorDisabled = transparentize(getColor(theme, 'white'), 0.32)(theme);

      if (c === 'secondary' || c === 'gray') {
        bg = getColor(theme, `${c}.400`);
        bgHover = getColor(theme, `${c}.500`);
        color = getColor(theme, `${c}.800`);
        colorDisabled = 'gray.500';
      }

      return {
        borderRadius: '4px',
        bg,
        color,
        _hover: {
          bg: bgHover,
          color,
          _disabled: {
            bg,
            color: colorDisabled,
          },
        },
        _focus: {
          bg,
        },
        _active: {
          bg,
        },
        _disabled: {
          bg,
          color: colorDisabled,
        },
      };
    },
    transparent: (props) => {
      const { colorScheme: c, theme } = props;
      let color = getColor(theme, `${c}.500`);
      let colorHover = getColor(theme, `${c}.600`);
      let colorDisabled = transparentize(getColor(theme, 'white'), 0.32)(theme);

      if (c === 'secondary' || c === 'gray') {
        color = getColor(theme, `${c}.400`);
        colorHover = getColor(theme, `${c}.500`);
        colorDisabled = 'gray.500';
      }

      return {
        padding: 0,
        bg: 'transparent',
        color,
        _hover: {
          bg: 'transparent',
          color: colorHover,
          _disabled: {
            bg: 'transparent',
            color: colorDisabled,
          },
        },
        _disabled: {
          bg: 'transparent',
          color: colorDisabled,
        },
        _focus: {
          bg: 'transparent',
          color: colorHover,
        },
        _active: {
          bg: 'transparent',
          color: colorHover,
        },
      };
    },
    outline: (props) => {
      const { colorScheme: c, theme } = props;
      let borderColor = getColor(theme, `${c}.500`);
      let bgHover = getColor(theme, `${c}.100`);
      let color = getColor(theme, `${c}.500`);
      const colorDisabled = 'gray.500';

      if (c === 'secondary' || c === 'gray') {
        borderColor = getColor(theme, `${c}.500`);
        bgHover = getColor(theme, `${c}.400`);
        color = getColor(theme, `${c}.800`);
      }

      if (c === 'primary' || c === 'blue') {
        bgHover = '#E3F1F8';
      }

      if (c === 'warning' || c === 'yellow') {
        borderColor = getColor(theme, `${c}.800`);
        bgHover = getColor(theme, `${c}.400`);
        color = getColor(theme, `${c}.900`);
      }

      return {
        borderRadius: '4px',
        bg: 'transparent',
        borderColor,
        color,
        _hover: {
          bg: bgHover,
          color,
          _disabled: {
            bg: 'transparent',
            color: colorDisabled,
            borderColor: 'gray.500',
          },
        },
        _disabled: {
          bg: 'transparent',
          color: colorDisabled,
          borderColor: 'gray.500',
        },
      };
    },
    inverted: {
      borderRadius: '4px',
      bg: 'transparent',
      borderColor: 'white',
      color: 'white',
      _hover: {
        bg: 'transparent',
        color: 'white',
        _disabled: {
          bg: 'transparent',
          color: 'gray.500',
          borderColor: 'gray.500',
        },
      },
      _disabled: {
        bg: 'transparent',
        color: 'gray.500',
        borderColor: 'gray.500',
      },
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'solid',
    colorScheme: 'secondary',
  },
};

export default buttonTheme;
