import { Modal, Text } from 'components';
import { IAlert } from 'types';

interface AlertProps {
  alert: IAlert;
  setAlert: (arg: any, timeout: any) => void;
  size?: 'sm' | 'md' | 'lg';
}
export const Alert = (props: AlertProps) => {
  const { alert, size = 'lg' } = props;
  const onClose = () => alert.clearAlert();

  return (
    <Modal isOpen={alert && alert.error} onClose={onClose} size={size}>
      <Text fontSize={'lg'}>{alert.message}</Text>
    </Modal>
  );
};
