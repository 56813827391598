import create from 'zustand';
import { devtools } from 'zustand/middleware';

export const useLayout = create(
  devtools(
    (set, get) => ({
      setDimensions: (divId: string, dimensions: any) => set({ [divId]: dimensions }),
    }),
    {
      name: 'crud-layout-store',
    },
  ),
);
