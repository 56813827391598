import React, { useEffect, useMemo, useCallback, useState } from 'react';
import moment from 'moment';
import { Box, Grid, GridItem, Flex, useDisclosure } from '@chakra-ui/react';
import _get from 'lodash/get';

import CRUD, { useCRUD, Table, NumberFormat } from 'Library/CRUD';
import { Alert, Text, Modal, ModalProps } from 'components';
import { useAlert } from 'utils';
import { useDB, useStore, useAuth } from 'store';

import SettlementsItemDrilldown from './SettlementsItemDrilldown';

interface ISettlementsDrilldown {
  data: any;
  parent: any;
  refreshParent: any;
  applyFees: (id: string) => {};
}

const SettlementsDrilldown = (props: ISettlementsDrilldown) => {
  const { data, parent, refreshParent, applyFees, ...o } = props;

  const [alert, setAlert] = useAlert();
  const { isOpen, onOpen, onClose } = useDisclosure();

  //FACILITATE CHILD REFRESH FROM PARENT
  let childRefresh = _get(props, 'filter._childRefresh', null);
  useEffect(() => {
    if (crud && crud.refresh) crud.refresh({ message: 'Settlements Drilldown Child Refresh' });
    // eslint-disable-next-line
  }, [childRefresh]);

  const [localData, setLocalData] = useState<any[]>([]);

  const crud = useCRUD({
    id: 'settlementsdrilldown',
    hasEditor: false,
    keyField: 'id',
    setAlert: setAlert,
    drilldown: true,
    filter: {
      startDate: moment().subtract(2, 'days').toDate(),
      endDate: new Date(),
    },
    fetch: async () => {
      const arr = data.Summary.BalancePerCardType.map((v: any) => ({
        ...v,
        SettlementID: data.Summary.SettlementID,
      }));
      setLocalData(arr);
      return arr;
    },
  });

  const getCardType = useCallback((cardType: string) => {
    switch (cardType) {
      case 'AX':
      case 'AMEX':
        return 'American Express';
      case 'VI':
      case 'VISA':
        return 'Visa';
      case 'MC':
      case 'MCARD':
        return 'Mastercard';
      default:
        return 'Other';
    }
  }, []);

  const columns = useMemo(
    () => [
      {
        name: 'Payment Type',
        selector: (row: any) => getCardType(row['CardType']),
        width: '170px',
        sortable: true,
      },
      {
        name: 'Number of Transactions',
        selector: (row: any) => row['NumberOfTransactions'] || 0,
        wrap: true,
        sortable: true,
      },
      {
        name: 'Payments',
        selector: (row: any) => row['Credit'],
        sortable: true,
        width: '100px',
        format: (row: any) => <NumberFormat amount={row.Credit} color={'green'} />,
        right: true,
      },
      {
        name: 'Refunds',
        selector: (row: any) => row['Debit'],
        sortable: true,
        width: '100px',
        format: (row: any) => <NumberFormat amount={row.Debit} color={'red'} />,
        right: true,
      },
      {
        name: 'Fees',
        selector: (row: any) => row['Fee'],
        width: '100px',
        sortable: true,
        format: (row: any) => <NumberFormat amount={row.Fee} color={'red'} />,
        right: true,
      },
      {
        name: 'Total',
        selector: (row: any) => row['Balance'],
        width: '140px',
        sortable: true,
        format: (row: any) => <NumberFormat amount={row.Balance} color={'green'} />,
        right: true,
      },
    ],
    [getCardType],
  );

  const conditionalRowStyles = [
    {
      when: (row: any) =>
        row.type === 'PAYMENT' && (row.status === 'VERIFIED' || row.status === 'COMPLETE'),
      style: {
        color: 'green',
      },
    },
    {
      when: (row: any) =>
        row.type === 'PREAUTH' && (row.status === 'VERIFIED' || row.status === 'COMPLETE'),
      style: {
        color: 'blue',
      },
    },
    {
      when: (row: any) =>
        row.type === 'REFUND' && (row.status === 'VERIFIED' || row.status === 'COMPLETE'),
      style: {
        color: 'darkorange',
      },
    },
    {
      when: (row: any) => row.status === 'ERROR',
      style: {
        color: 'red',
      },
    },
  ];

  return (
    <>
      {isOpen && (
        <AllTransactionsModal
          isOpen={isOpen}
          onClose={onClose}
          setAlert={setAlert}
          settlementID={localData[0].SettlementID}
        />
      )}

      <Box fontFamily={'Roboto Condensed'} h="100%">
        <CRUD id="settlementsdrilldown" crud={crud} {...o}>
          <Alert alert={alert} setAlert={setAlert} />
          <Grid templateColumns="1fr 150px">
            <GridItem>
              <Flex mt={2} direction="row" fontSize={14}>
                <Text fontWeight={'bold'} mr={1}>
                  Payment Types
                </Text>
              </Flex>
            </GridItem>
          </Grid>
          <Table
            id="settlements_drilldown"
            crud={crud}
            columns={columns}
            responsive
            paginationContext={true}
            conditionalRowStyles={conditionalRowStyles}
            expandableRows
            expandableRowsComponent={SettlementsItemDrilldown}
          />
          <Grid templateColumns="1fr 150px" my={'8px'}>
            <GridItem>
              <Flex mt={2} direction="row" fontSize={14}>
                <Text variant={'linkSmallSemiBold'} cursor={'pointer'} onClick={onOpen} mr={1}>
                  View All Transactions
                </Text>
              </Flex>
            </GridItem>
          </Grid>
        </CRUD>
      </Box>
    </>
  );
};

interface AllTransactionsModalProps extends Omit<ModalProps, 'children'> {
  setAlert: any;
  settlementID: string;
}
const AllTransactionsModal: React.FC<AllTransactionsModalProps> = React.memo((props) => {
  const { isOpen, onClose, setAlert, settlementID } = props;

  const lookup = useStore((state) => state.lookup);
  const propertyID = Object.keys(lookup.Merchant)?.[0];
  const timezone = lookup.Merchant?.[propertyID as any]?.Timezone;

  const crud = useCRUD({
    id: 'settlementsitemdrilldown_filtered',
    hasEditor: false,
    keyField: 'id',
    setAlert: setAlert,
    drilldown: true,
    filter: {
      startDate: moment().subtract(2, 'days').toDate(),
      endDate: new Date(),
    },
    fetch: async () => {
      const result = await useDB.getState().axios({
        server: 'PAYGATE',
        method: 'GET',
        url: `/api/v1/transactions/settlement/${settlementID}`,
      });
      const arr = result.data.data.map((v: any) => ({
        ...v,
        Date: timezone
          ? moment.utc(v.Date).tz(timezone).format('DD/MM/YYYY')
          : moment(v.Date).format('DD/MM/YYYY'),
      }));
      return arr;
    },
  });

  const getCardType = useCallback((cardType: string) => {
    switch (cardType) {
      case 'AX':
      case 'AMEX':
        return 'American Express';
      case 'VI':
      case 'VISA':
        return 'Visa';
      case 'MC':
      case 'MCARD':
        return 'Mastercard';
      default:
        return 'Other';
    }
  }, []);

  const columns = useMemo(
    () => [
      {
        name: 'Date',
        selector: (row: any) => row['Date'] ?? '-',
        width: '105px',
        sortable: true,
      },

      {
        name: 'Payment Type',
        selector: (row: any) => (row['CardType'] ? getCardType(row['CardType']) : '-'),
        width: '130px',
        sortable: true,
      },
      {
        name: 'Type',
        selector: (row: any) => row['TransactionType'] ?? '-',
        width: '210px',
        sortable: true,
      },
      {
        name: 'Tran Ref',
        selector: (row: any) => row['ProviderReference'] ?? '-',
        sortable: true,
        width: '100px',
      },
      {
        name: 'Tran Mode',
        selector: (row: any) => row['TransactionMode'] ?? '-',
        sortable: true,
        width: '100px',
      },
      {
        name: 'Description',
        selector: (row: any) => row['Reason'] ?? '-',
        sortable: true,
      },
      {
        name: 'Reservation #',
        selector: (row: any) => row['ReservationNumber'] ?? '-',
        sortable: true,
      },
      {
        name: 'Amount',
        selector: (row: any) => row['SettledAmount'] ?? '-',
        width: '85px',
        sortable: true,
        format: (row: any) => (
          <>
            {row.SettledAmount !== '-' && row.SettledAmount !== null ? (
              <NumberFormat
                amount={row.SettledAmount}
                color={row.TransactionType === 'REFUND' ? 'red' : 'green'}
              />
            ) : (
              '-'
            )}
          </>
        ),
      },
    ],
    [getCardType],
  );

  const mode = useAuth((state) => state.user.Mode);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      cancelText={'Close'}
      title={'All Transactions'}
      okProps={{ display: 'none' }}
      height={'600px'}
      sidebarOverlay={true}
      size={'lg'}
      {...(mode === 'API' && { modalSpace: '0px' })}
    >
      <Box fontFamily={'Roboto Condensed'} h="100%">
        <Table
          id="settlements_all_transactions"
          crud={crud}
          columns={columns}
          responsive
          maxH={'450px'}
        />
      </Box>
    </Modal>
  );
});

export default React.memo(SettlementsDrilldown);
