import { SystemStyleObject, ThemeThunk } from '@chakra-ui/styled-system';

interface FormTheme {
  baseStyle: ThemeThunk<SystemStyleObject>;
  defaultProps: { variant: string };
}

const formTheme: FormTheme = {
  baseStyle: (props) => ({
    helperText: {
      color: props.isDisabled ? 'gray.500' : 'gray.700',
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: 400,
      mt: '9px',
    },
  }),
  defaultProps: {
    variant: 'unstyled',
  },
};

export default formTheme;
