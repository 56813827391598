import * as React from 'react';
import { Menu, MenuProps, StylesProvider, useMultiStyleConfig } from '@chakra-ui/react';

export interface DropdownProps extends Omit<MenuProps, 'size'> {
  /**
   * This will only affect the contents inside DropdownList.
   * To customize the size of Button, you have to manually hardcode the size
   * @default md
   */
  size?: 'sm' | 'md';
}

const Dropdown: React.FC<DropdownProps> = (props) => {
  const { children, size = 'md', autoSelect = false, closeOnSelect = true, ...rest } = props;
  const styles = useMultiStyleConfig('Menu', { size });

  return (
    <StylesProvider value={styles}>
      <Menu size={size} autoSelect={autoSelect} closeOnSelect={closeOnSelect} gutter={11} {...rest}>
        {children}
      </Menu>
    </StylesProvider>
  );
};

export default Dropdown;
