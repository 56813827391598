import React, { useState, useMemo } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { useAuth } from 'store';

import { Dashboard, MerchantDashboard, Management, Settlements, Transactions, Reports } from '.';
import {
  Control,
  Errors,
  Merchants,
  Provider,
  Notifications,
  Outbound,
  RatePlans,
  Rates,
  Requests,
  Schedule,
  Templates,
  Users,
} from './Management';

const Restrict = (props: any) => {
  const { redirectTo, condition, element } = props;
  return condition ? element : <Navigate to={redirectTo} />;
};

const AppRoutes = () => {
  const user = useAuth((state) => state.user);
  const authLogout = useAuth((state) => state.logout);

  const [options, setOptions] = useState({});

  const apiMode = window.location.search;
  const strLink = apiMode
    ? apiMode.includes('timestamp')
      ? apiMode.split('&timestamp=')[0].replace('?api=', '')
      : apiMode.replace('?api=', '')
    : '';

  const defaultPath = useMemo(() => {
    let path = '';
    if (user.AllowViewPaymentSummary && !path) {
      path = '/dashboard';
    }
    if (user.AllowViewPaymentTransactions && !path) {
      path = '/transactions';
    }
    if (user.AllowViewPaymentSettlements && !path) {
      path = '/settlements';
    }
    if (user.AllowViewPaymentReports && !path) {
      path = '/reports';
    }
    return path;
  }, [user]);

  if (apiMode) {
    switch (strLink) {
      case 'summary':
        if (!user.AllowViewPaymentSummary) break;
        return <MerchantDashboard options={options} setOptions={setOptions} />;
      case 'transactions':
        if (!user.AllowViewPaymentTransactions) break;
        return <Transactions />;
      case 'settlements':
        if (!user.AllowViewPaymentSettlements) break;
        return <Settlements />;
      case 'reports':
        if (!user.AllowViewPaymentReports) break;
        return <Reports />;
      default:
        if (!user.AllowViewPaymentSummary) break;
        return <MerchantDashboard options={options} setOptions={setOptions} />;
    }

    switch (defaultPath) {
      case '/summary':
        return <MerchantDashboard options={options} setOptions={setOptions} />;
      case '/transactions':
        return <Transactions />;
      case '/settlements':
        return <Settlements />;
      case '/reports':
        return <Reports />;
      default:
        return <>{'not authorized'}</>;
    }
  }

  return (
    <>
      <Routes>
        <Route
          path="*"
          element={
            <Navigate
              to={user.Mode === 'API' ? '/paygate' : authLogout ? '' : defaultPath}
              replace
            />
          }
        />
        {user.AllowViewPaymentSummary && (
          <Route
            path="/dashboard"
            element={
              user.Type === 'MERCHANT' || user.Mode === 'API' ? (
                <MerchantDashboard options={options} setOptions={setOptions} />
              ) : (
                <Dashboard />
              )
            }
          />
        )}
        {/* MANAGEMENT TAB IS CONDITIONAL UPON ADMIN PRIVILEGE */}
        <Route
          path="management"
          element={
            <Restrict condition={user.Type === 'ADMIN'} redirectTo="/" element={<Management />} />
          }
        >
          {/* OPERATIONAL */}
          <Route path="review" element={<Management />} />
          <Route path="review/notifications" element={<Notifications />} />
          {/* SETUP */}
          <Route path="setup" element={<Management />} />
          <Route path="setup/control" element={<Control />} />
          <Route path="setup/merchants" element={<Merchants />} />
          <Route path="setup/provider" element={<Provider />} />
          <Route path="setup/templates" element={<Templates />} />
          <Route path="setup/rateplans" element={<RatePlans />} />
          <Route path="setup/rates" element={<Rates />} />
          <Route path="setup/schedule" element={<Schedule />} />
          <Route path="setup/users" element={<Users />} />
          {/* LOGGING */}
          <Route path="logging" element={<Management />} />
          <Route path="logging/outbound" element={<Outbound />} />
          <Route path="logging/requests" element={<Requests />} />
          <Route path="logging/errors" element={<Errors />} />
        </Route>

        {/*{user.AllowViewPaymentReports && <Route path='/reports' element={<Reports />} /> } */}
        {user.AllowViewPaymentSettlements && (
          <Route path="/settlements" element={<Settlements />} />
        )}
        {user.AllowViewPaymentTransactions && (
          <Route path="/transactions" element={<Transactions options={options} />} />
        )}
        {user.AllowViewPaymentReports && (
          <Route path="/reports" element={<Reports />} />
        )}
      </Routes>
      {/* <ManagementRoutes /> */}
    </>
  );
};

export default React.memo(AppRoutes);

