import React from 'react';

import { Button } from 'components';

const BtnCustom = (props) => {
  const { crud, id = 'btn-custom', leftIcon, label = '?', color, onClick, ...otherProps } = props;
  return (
    <Button
      variant="outline"
      colorScheme="blackAlpha"
      leftIcon={leftIcon}
      id={id}
      onClick={onClick}
      {...otherProps}
    >
      {label}
    </Button>
  );
};

export default React.memo(BtnCustom);
