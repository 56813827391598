import React, { useState, useEffect, useRef, Dispatch, SetStateAction } from 'react';
import {
  Flex,
  forwardRef,
  useMultiStyleConfig,
  useOutsideClick,
  Spacer,
  FlexProps,
} from '@chakra-ui/react';
import {
  set,
  getMonth,
  getYear,
  startOfMonth,
  startOfYear,
  subYears,
  addYears,
  format,
  isEqual,
  getDate,
} from 'date-fns';
import DatePicker from 'react-datepicker';
import './datepicker.styles.css';
import 'react-datepicker/dist/react-datepicker.css';

import { Button, Text, ButtonProps, Icon, TextProps, Dropdown, DropdownButton } from 'components';
import { formatDate } from 'utils/dateUtils';

export type TPickerType = 'day' | 'month' | 'year';

interface Props extends Omit<FlexProps, 'value' | 'onChange'> {
  /**
   * Contains { from, to }
   */
  value: Date;
  /**
   * SetState functionality for dateRange
   */
  onChange?: Dispatch<SetStateAction<Date>> | ((selectedDate: Date) => void);

  hasFooter?: boolean;
  footerText?: string;

  minDate?: Date;
  maxDate?: Date;

  /**
   * Number of months to be shown
   * Default is 2
   */
  numberOfMonths?: number;

  buttonProps?: ButtonProps;

  /**
   * Text props
   * 9 dec 2021 - 9 dec 2021
   */
  textProps?: TextProps;
  dynamicRight?: boolean;
  portalEnabled?: boolean;
  calendarContainerBottom?: string;
}

const DatePickerCustom: React.FC<Props> = forwardRef((props, ref) => {
  const {
    value: valueProp,
    onChange,
    hasFooter = true,
    footerText,
    minDate,
    maxDate,
    numberOfMonths = 1,
    buttonProps,
    textProps,
    children,
    dynamicRight,
    portalEnabled = true,
    calendarContainerBottom,
  } = props;
  const [inputValue, setInputValue] = useState<Date>(valueProp);
  const [showPicker, setShowPicker] = useState(false);
  const [pickerType, setPickerType] = useState<TPickerType>('day');
  const [viewedDate, setViewedDate] = useState(valueProp);
  const refContainer = useRef<HTMLDivElement>(null);
  const styles = useMultiStyleConfig('DateRange', {});

  // reset inputValue when valueProp changes, e.g. when pressing the TodayIcon (?) in the ResPlan
  useEffect(() => {
    setInputValue(valueProp);
  }, [valueProp]);

  useOutsideClick({
    ref: refContainer,
    handler: () => {
      if (showPicker) {
        setViewedDate(valueProp || new Date());
        setInputValue(valueProp);
        setShowPicker(false);
      }
    },
  });

  const handleChange = (dateArg: Date) => {
    const dateChange = set(dateArg, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });

    let newDate = inputValue
      ? set(inputValue, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
      : null;

    // x = inputValue.from
    // y = selectedDate
    // z = inputValue.to
    if (!newDate) {
      newDate = dateChange;
    } else if (isEqual(dateChange, newDate)) {
      return;
    } else {
      newDate = dateChange;
    }

    if (pickerType === 'day') {
      setInputValue(newDate);
      if (!hasFooter) {
        setViewedDate(newDate);
        setShowPicker(false);
        onChange?.(newDate);
      }
      return;
    }

    const val = inputValue || new Date();
    const month = getMonth(dateChange);
    const year = getYear(dateChange);

    const opts = pickerType === 'month' ? { year, month } : { year };
    const fixedDate = set(val, opts);

    setViewedDate(fixedDate);
    setPickerType(pickerType === 'year' ? 'month' : 'day');
  };

  const CalendarContainer: React.FC<any> = ({ className, children }) => (
    <Flex
      sx={styles.pickerContainer}
      position={'absolute'}
      ref={refContainer}
      right={dynamicRight ? ['190px', '190px', '190px', '190px', '0px', '0px'] : '0px'}
      {...(calendarContainerBottom
        ? {
            bottom: calendarContainerBottom,
          }
        : {})}
    >
      <Flex sx={styles.picker} className={className} flexDir={'column'}>
        <Flex sx={styles.calendar} className={className}>
          {children}
        </Flex>
        {/* optional footer */}
        {hasFooter && pickerType === 'day' && (
          <Flex
            bg={'gray.200'}
            p={'8px 12px'}
            alignItems={'center'}
            borderTop={'4px solid'}
            borderColor={'gray.400'}
          >
            {footerText && (
              <Text variant={'labelExtraSmallSemiBold'} color={'gray.800'}>
                {footerText}
              </Text>
            )}
            <Spacer />
            <Button
              size={'sm'}
              label={'Cancel'}
              onClick={() => {
                setInputValue(valueProp);
                setViewedDate(valueProp);
                setShowPicker(false);
              }}
              mr={'6px'}
            />
            <Button
              size={'sm'}
              colorScheme={'primary'}
              label={'Apply'}
              onClick={() => {
                onChange?.(inputValue);
                setViewedDate(inputValue);
                setShowPicker(false);
              }}
            />
          </Flex>
        )}
      </Flex>
    </Flex>
  );

  let fixedMinDate = minDate;
  if (minDate) {
    if (pickerType === 'month') {
      fixedMinDate = startOfMonth(minDate);
    }

    if (pickerType === 'year') {
      fixedMinDate = startOfYear(minDate);
    }
  }

  const dateFormat = 'd MMM yyyy';
  const CustomButton = forwardRef((_, ref) => {
    const checkDateRange = () => {
      if (inputValue !== null) {
        return (
          <Text variant="labelSmallMedium" {...textProps}>
            {`${formatDate(inputValue, 'd MMM yyyy')}`}
          </Text>
        );
      } else {
        return (
          <Text color="gray.500" align="left">
            {'Select an option'}
          </Text>
        );
      }
    };

    return (
      <Dropdown>
        {/* Use DropdownButton instead of Button to let the leftIcon align properly instead of changing its location depending on the text width */}
        <DropdownButton
          colorScheme="secondary"
          size="sm"
          leftIcon={<Icon variant="CalendarMain" />}
          rightIcon={<Icon variant="CaretDown" />}
          onClick={() => {
            setShowPicker(!showPicker);
          }}
          minWidth={'140px'}
          as={Button}
          ref={ref}
          {...buttonProps}
        >
          {children || checkDateRange()}
        </DropdownButton>
      </Dropdown>
    );
  });

  const renderDayContents = (_: any, date: Date) => (
    <Flex className={'react-datepicker__day--custom'}>{getDate(date)}</Flex>
  );

  return (
    <DatePicker
      autoFocus={true}
      popperPlacement={'bottom-start'}
      popperModifiers={[
        { name: 'flip', options: { fallbackPlacements: ['top-start'] } },
        { name: 'preventOverflow', options: { rootBoundary: 'viewport' } },
      ]}
      renderDayContents={renderDayContents}
      openToDate={pickerType === 'day' ? undefined : viewedDate || undefined}
      selectsRange={false}
      monthsShown={pickerType === 'day' ? numberOfMonths : 1}
      ref={ref}
      shouldCloseOnSelect={false}
      focusSelectedMonth={false}
      open={showPicker}
      dateFormat={dateFormat}
      selected={inputValue}
      customInput={<CustomButton />}
      onChange={handleChange}
      minDate={fixedMinDate}
      maxDate={maxDate}
      portalId={portalEnabled ? 'date-picker-portal' : ''}
      calendarContainer={CalendarContainer}
      showMonthYearPicker={pickerType === 'month'}
      showYearPicker={pickerType === 'year'}
      renderCustomHeader={({
        monthDate: dateVal,
        date: dateValYear,
        decreaseMonth,
        increaseMonth,
        decreaseYear,
        increaseYear,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
        prevYearButtonDisabled,
        nextYearButtonDisabled,
        customHeaderCount,
      }) => {
        const fixedDate = pickerType === 'year' ? dateValYear : dateVal;

        const month = format(fixedDate, 'LLLL');
        const year = getYear(fixedDate);

        const text = `${month} ${year}`;
        if (pickerType === 'day') {
          return (
            <Flex mb={'10px'} sx={styles.captionContainer}>
              <Icon
                sx={styles.navigationIcon}
                variant={'CaretLeft'}
                onClick={() => {
                  decreaseMonth();
                }}
                size={6}
                aria-label={'prevMonthButton'}
                aria-disabled={prevMonthButtonDisabled}
                {...(customHeaderCount === 1 && { visibility: 'hidden' })}
              />
              <Text
                variant={'heading06Bold'}
                onClick={() => setPickerType('month')}
                sx={styles.captionText}
              >
                {text}
              </Text>
              <Icon
                sx={styles.navigationIcon}
                variant={'CaretRight'}
                onClick={() => {
                  increaseMonth();
                }}
                size={6}
                aria-label={'nextMonthButton'}
                aria-disabled={nextMonthButtonDisabled}
                {...(customHeaderCount === 1 && { visibility: 'hidden' })}
              />
            </Flex>
          );
        }

        // month
        if (pickerType === 'month') {
          return (
            <Flex sx={styles.captionContainer}>
              <Icon
                sx={styles.navigationIcon}
                variant={'CaretLeft'}
                onClick={decreaseYear}
                size={6}
                aria-label={'prevYearButton'}
                aria-disabled={prevYearButtonDisabled}
              />
              <Text
                variant={'heading06Bold'}
                onClick={() => setPickerType('year')}
                sx={styles.captionText}
              >
                {getYear(dateVal)}
              </Text>
              <Icon
                sx={styles.navigationIcon}
                variant={'CaretRight'}
                onClick={increaseYear}
                size={6}
                aria-label={'nextYearButton'}
                aria-disabled={nextYearButtonDisabled}
              />
            </Flex>
          );
        }

        // year
        const YEAR_ITEM_NUMBER = 12;
        const getYearsPeriod = (date: Date) => {
          const endPeriod = Math.ceil(getYear(date) / YEAR_ITEM_NUMBER) * YEAR_ITEM_NUMBER;
          const startPeriod = endPeriod - (YEAR_ITEM_NUMBER - 1);
          return { startPeriod, endPeriod };
        };

        const yearsDisabledBefore = (date: Date) => {
          const previousYear = startOfYear(subYears(date, YEAR_ITEM_NUMBER));
          const { endPeriod } = getYearsPeriod(previousYear);
          const minDateYear = minDate && getYear(minDate);
          return (minDateYear && minDateYear > endPeriod) || false;
        };

        const yearsDisabledAfter = (date: Date) => {
          const nextYear = addYears(date, YEAR_ITEM_NUMBER);
          const { startPeriod } = getYearsPeriod(nextYear);
          const maxDateYear = maxDate && getYear(maxDate);
          return (maxDateYear && maxDateYear < startPeriod) || false;
        };

        const { startPeriod, endPeriod } = getYearsPeriod(fixedDate);
        if (pickerType === 'year') {
          return (
            <Flex sx={styles.captionContainer}>
              <Icon
                sx={styles.navigationIcon}
                variant={'CaretLeft'}
                onClick={decreaseYear}
                size={6}
                aria-label={'prevYearButton'}
                aria-disabled={yearsDisabledBefore(fixedDate)}
              />
              <Text variant={'heading06Bold'} sx={styles.captionText} aria-disabled={true}>
                {startPeriod} - {endPeriod}
              </Text>
              <Icon
                sx={styles.navigationIcon}
                variant={'CaretRight'}
                onClick={increaseYear}
                size={6}
                aria-label={'nextYearButton'}
                aria-disabled={yearsDisabledAfter(fixedDate)}
              />
            </Flex>
          );
        }
      }}
    />
  );
});

export default DatePickerCustom;
