import { useState } from 'react';
import { IAlert } from 'types';

export const useAlert = (args?: any): [IAlert, (arg: any, timeout: any) => void] => {
  const options = {
    active: false,
    modal: false,
    title: '',
    message: '',
    color: 'warning',
    icon: 'info-circle', //Note: When called can have prefix far:
    ...args,
  };
  const [state, setState] = useState<IAlert>(options);

  const setAlert = (arg: any, timeout = null) => {
    if (typeof arg === 'boolean') {
      if (arg === true) setState({ ...state, active: true });
      else setState({ ...state, active: false, exclusive: false });
    } else if (typeof arg === 'string') {
      setState({ ...state, message: arg, active: true });
    } else {
      setState({ ...state, ...arg });
    }
    if (timeout) setTimeout(() => setState(options), timeout);
  };

  return [{ ...state }, setAlert];
};
