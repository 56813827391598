import { useMemo } from 'react';
import moment from 'moment';
import { Box, GridItem } from '@chakra-ui/react';
import { FaCheck, FaTimes } from 'react-icons/fa';

import CRUD, {
  useCRUD,
  Table,
  FilterInputSearch,
  FilterInputDateRange,
  FilterInputSelect,
  Filters,
  BtnRefresh,
} from 'Library/CRUD';
import { getSelect } from 'Library';
import { useStore, useDB } from 'store';
import { Alert } from 'components';
import { useAlert } from 'utils';

import { ErrorDetail } from './ErrorDetail';

export const Errors = () => {
  const [alert, setAlert] = useAlert();

  const lookup = useStore((state) => state.lookup);
  const merchantSelect = getSelect(lookup.Merchant, {
    keyField: 'code',
    label: 'code',
    selectAll: { value: '', label: 'All' },
  });

  const crud = useCRUD({
    id: 'requests',
    title: 'Error Logs',
    hasEditor: false,
    keyField: 'id',
    setAlert: setAlert,
    filter: {
      startDate: moment().subtract(1, 'days').toDate(),
      endDate: new Date(),
      type: '',
      merchant: '',
    },
    fetch: async (qry: string) => {
      // if (!filter.query) return [] //Wait until there is a filter
      const result = await useDB.getState().axios({
        server: 'PAYGATE',
        method: 'GET',
        url: `/api/management/error/fetch${qry || ''}`,
      });
      return result.data;
    },
  });

  let columns = useMemo(
    () => [
      {
        name: 'ID',
        selector: (row: any) => row['id'],
        width: '90px',
        sortable: true,
      },
      {
        name: 'Date',
        selector: (row: any) => row['date'],
        format: (rec: any) => moment(rec.date).format('DD/MM/YYYY @hh:mma'),
        width: '140px',
        sortable: true,
      },
      {
        name: 'Method',
        selector: (row: any) => row['method'],
        sortable: true,
        width: '90px',
      },
      {
        name: 'Route',
        selector: (row: any) => row['route'],
        sortable: true,
        maxWidth: '300px',
      },
      {
        name: 'Message',
        selector: (row: any) => row['message'],
        sortable: true,
        wrap: true,
      },
      {
        name: 'Authorised',
        selector: (row: any) => row['authorised'],
        cell: (row: any) => (row.authorised ? <FaCheck color="green" /> : <FaTimes color="red" />),
        sortable: true,
        center: true,
        width: '90px',
      },
    ],
    [],
  );

  const filters = useMemo(() => {
    return (
      <Filters
        templateColumns={[
          '1fr',
          '1fr 1fr',
          '1fr 1fr',
          '1fr 1fr',
          '400px 250px 180px  180px 1fr 150px',
        ]}
      >
        <FilterInputSearch crud={crud} />
        <FilterInputDateRange
          value={{
            from: moment(crud.filter.startDate).toDate(),
            to: moment(crud.filter.endDate).toDate(),
          }}
          onChange={(dates: any) => {
            crud.setFilter({
              ...crud.filter,
              startDate: dates[0],
              endDate: dates[1],
            });
          }}
        />

        <FilterInputSelect
          placeholder="Merchant..."
          defaultValue={crud.filter.merchant}
          width="150px"
          onChange={(val: string) => {
            crud.setFilter({ ...crud.filter, merchant: val });
          }}
          options={merchantSelect}
        />
        <FilterInputSelect
          placeholder="Select Type..."
          defaultValue={crud.filter.type || ''}
          width="150px"
          onChange={(val: string) => {
            crud.setFilter({ ...crud.filter, type: val });
          }}
          options={[
            {
              label: 'All',
              value: '',
            },
            {
              label: 'Payment',
              value: 'PAYMENT',
            },
            {
              label: 'Pre-Auth',
              value: 'PREAUTH',
            },
            {
              label: 'Refund',
              value: 'REFUND',
            },
            {
              label: 'Claim',
              value: 'CLAIM',
            },
          ]}
        />
        <GridItem />
        <BtnRefresh crud={crud} cascade={true} />
      </Filters>
    );
    // eslint-disable-next-line
  }, [crud.filter]);

  const conditionalRowStyles = [
    {
      when: (row: any) => row.type === 'PAYMENT' && row.net > 0,
      style: {
        color: 'green',
        backgroundColor: '#f3f8f3',
        fontWeight: 600,
      },
    },
    {
      when: (row: any) => row.type === 'PREAUTH' && row.net > 0,
      style: {
        color: 'purple',
        backgroundColor: '#f6f1f6',
        fontWeight: 600,
      },
    },
  ];

  return (
    <Box fontFamily={'Roboto Condensed'} h="100%">
      <CRUD id="requestss" crud={crud} filters={filters}>
        <Alert alert={alert} setAlert={setAlert} />

        <Table
          id="bank_table"
          crud={crud}
          columns={columns}
          responsive
          expandableRows
          expandableRowsComponent={ErrorDetail}
          conditionalRowStyles={conditionalRowStyles}
          striped={false}
        ></Table>
      </CRUD>
    </Box>
  );
};
