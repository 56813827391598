import { primary } from 'theme/colorSchemes';

const parts = ['container', 'pickerContainer', 'picker'];

const baseStyle = () => {
  const rightHalfBackground = `linear-gradient(to left, ${primary[200]} 50%, transparent 50%)`;
  const leftHalfBackground = `linear-gradient(to right, ${primary[200]} 50%, transparent 50%)`;
  return {
    container: {
      //flex: 1,
    },
    pickerContainer: {
      //position: 'relative',
    },
    navigationIcon: {
      cursor: 'pointer',
      color: 'gray.700',
      _hover: {
        color: 'gray.800',
      },
      _disabled: {
        color: 'gray.500',
        pointerEvents: 'none',
      },
    },
    captionText: {
      cursor: 'pointer',
      px: '2px',
      borderRadius: '4px',
      mt: '2px',
      boxSizing: 'border-box',
      _hover: {
        backgroundColor: '#E3F1F8',
      },
      _active: {
        color: 'blue.500',
        backgroundColor: '#E3F1F8',
      },
      _disabled: {
        color: 'gray.500',
        pointerEvents: 'none',
        _hover: {
          backgroundColor: 'transparent',
        },
        _active: {
          backgroundColor: 'transparent',
        },
      },
    },
    captionContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      px: '12px',
    },
    picker: {
      _focus: {
        outline: 'none',
      },

      mt: '10px',
      position: 'absolute',
      borderColor: 'gray.400',
      // position: fixed ? 'fixed' : 'absolute',
      // transform: fixed ? `translate(${leftPositionButton - 5}px, ${topPositionButton + 30}px)` : 'initial',
      zIndex: 9999,
      borderWidth: 4,
      borderRadius: 4,
      // top: '2px',
      // left: 0,
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
      backgroundColor: 'gray.50',
      width: 'max-content',
    },
    calendar: {
      _focus: {
        outline: 'none',
      },
      border: 'none',

      // react-datepicker
      '.react-datepicker': {
        width: '100%',
        border: 'none',
        fontFamily: 'unset',
      },

      '.react-datepicker__header--custom': {
        width: '100%',
        px: '4px',
        pt: '16px',
        pb: '12px',
        border: 'none',
        bg: 'transparent',
      },

      // week
      '.react-datepicker__day-names': {
        width: '100%',
        display: 'flex',
        px: '12px',
      },

      '.react-datepicker__day-name': {
        display: 'block',
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '16px',
        color: 'gray.700',
        width: '40px',
        margin: '0px',
      },

      // month
      '.react-datepicker__monthPicker': {
        mb: '0px',
        py: '0px',
      },

      '.react-datepicker__month': {
        '&-wrapper': {
          display: 'flex',
          justifyContent: 'space-between',
          px: '6px',
        },
        '&-container': {
          float: 'unset',
        },
        '&--disabled': {
          color: 'gray.500',
          backgroundColor: 'transparent',
        },
        '&-text': {
          '&--disabled': {
            _hover: {
              borderColor: 'transparent',
              color: 'gray.500',
              backgroundColor: 'transparent',
            },
            color: 'gray.500',
            backgroundColor: 'transparent',
          },
        },
      },

      // year
      '.react-datepicker__year': {
        mb: '0.5px', // makes it the same height as month picker
        '&-wrapper': {
          maxWidth: '242px',
          p: '6px',
          py: '0px',
        },
        '&-text': {
          ':nth-of-type(3n)': {
            mr: 0,
          },
          '&--disabled': {
            _hover: {
              borderColor: 'transparent',
              color: 'gray.500',
              backgroundColor: 'transparent',
            },
            color: 'gray.500',
            backgroundColor: 'transparent',
          },
        },
      },

      '.react-datepicker__month, .react-datepicker__year': {
        margin: 0,
        mb: '4px',
        padding: '4px',
        pb: 0,
        boxSizing: 'border-box',
      },

      '.react-datepicker__month-text, .react-datepicker__year-text': {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        fontFamily: 'unset',
        mr: '52px',
        fontWeight: 700,
        width: '40px',
        height: '40px',
        _focus: {
          outline: 'none',
        },
        ':nth-of-type(3n)': {
          mr: 0,
        },
      },

      // day
      '.react-datepicker__week': {
        display: 'flex',
        px: '12px',
      },

      '.react-datepicker__day': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '2px solid transparent',
        margin: '0px',
        height: '40px',
        width: '40px',
        fontWeight: '700',
        fontSize: '12px',
        lineHeight: '16px',
        boxSizing: 'border-box',
        _hover: {
          border: '2px solid',
          borderColor: 'blue.500',
          borderRadius: '50%',
          backgroundColor: 'transparent',
        },
        _focus: {
          outline: 'none',
        },
        '&--outside-month': {
          visibility: 'hidden',
        },
        '&--today': {
          color: 'blue.500',
        },
        '&--disabled': {
          color: 'gray.500',
        },
      },

      // range
      '.react-datepicker__day--in-range': {
        backgroundColor: 'blue.200',
        color: 'gray.800',
        borderRadius: '0%',
        _hover: {
          color: 'gray.800',
          backgroundColor: 'transparent',
          borderColor: 'blue.500',
        },
      },

      '.react-datepicker__day--keyboard-selected.react-datepicker__day--in-range:not(.react-datepicker__day--range-end)':
        {
          backgroundColor: 'blue.200',
          color: 'gray.800',
          borderRadius: '0%',
          _hover: {
            color: 'gray.800',
            backgroundColor: 'transparent',
            borderColor: 'blue.500',
          },
        },

      '.react-datepicker__day--in-range.react-datepicker__day--today': {
        color: 'blue.500',
      },

      '.react-datepicker__day--range-start': {
        borderRadius: '0%',
        border: '0px',
        background: rightHalfBackground,

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        _hover: {
          border: 'none',
          borderRadius: '0%',
          background: rightHalfBackground,
        },
        '& > .react-datepicker__day--custom': {
          alignItems: 'center',
          justifyContent: 'center',
          minWidth: '40px',
          height: '40px',
          boxSizing: 'border-box',
          backgroundColor: 'blue.500',
          color: 'white',
          borderRadius: '50%',
          _hover: {
            color: 'white',
            backgroundColor: 'blue.500',
          },
        },
      },

      '.react-datepicker__day--range-end': {
        borderRadius: '0%',
        border: '0px',
        background: leftHalfBackground,

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        _hover: {
          border: 'none',
          borderRadius: '0%',
          background: leftHalfBackground,
        },
        '& > .react-datepicker__day--custom': {
          alignItems: 'center',
          justifyContent: 'center',
          minWidth: '40px',
          height: '40px',
          boxSizing: 'border-box',
          backgroundColor: 'blue.500',
          color: 'white',
          borderRadius: '50%',
          _hover: {
            color: 'white',
            backgroundColor: 'blue.500',
          },
        },
      },

      '.react-datepicker__day--range-start.react-datepicker__day--range-end': {
        background: 'blue.200',
      },

      // selected/disabled/etc. styling
      '.react-datepicker__month-text:not(.react-datepicker__month--selected)': {
        backgroundColor: 'transparent',
        color: 'gray.800',
        _hover: {
          border: '2px solid',
          backgroundColor: 'transparent',
          borderColor: 'blue.500',
          borderRadius: '50%',
        },
        '&.react-datepicker__month--disabled': {
          color: 'gray.500',
          _hover: {
            borderColor: 'transparent',
          },
        },
      },

      '.react-datepicker__year-text:not(.react-datepicker__year-text--selected)': {
        backgroundColor: 'transparent',
        color: 'gray.800',
        _hover: {
          border: '2px solid',
          backgroundColor: 'transparent',
          borderColor: 'blue.500',
          borderRadius: '50%',
        },
        '&.react-datepicker__year-text--disabled': {
          color: 'gray.500',
          _hover: {
            borderColor: 'transparent',
          },
        },
      },

      '.react-datepicker__month--disabled, .react-datepicker__year-text--disabled': {
        backgroundColor: 'transparent',
        color: 'gray.500',
        _hover: {
          borderColor: 'transparent',
        },
      },

      '.react-datepicker__month--selected, .react-datepicker__year-text--selected': {
        backgroundColor: 'blue.500',
        _hover: {
          color: 'white',
          backgroundColor: 'blue.500',
        },
      },

      '.react-datepicker__month-text--keyboard-selected.react-datepicker__month--disabled': {
        backgroundColor: 'transparent',
        color: 'gray.500',
      },

      '.react-datepicker__year-text--keyboard-selected.react-datepicker__year-text--disabled': {
        backgroundColor: 'transparent',
        color: 'gray.500',
      },

      '.react-datepicker__day--selected:not(.react-datepicker__day--disabled):not(.react-datepicker__day--outside):not(.react-datepicker__day--range-start)':
        {
          backgroundColor: 'blue.500',
          borderRadius: '50%',
          color: 'white',
        },

      '.react-datepicker__day--disabled, .react-datepicker__day--outside': {
        pointerEvents: 'none',
        _hover: {
          borderColor: 'transparent',
        },
      },

      '.react-datepicker__day--keyboard-selected:not(.react-datepicker__day--selected):not(.react-datepicker__day--range-end):not(.react-datepick__day--in-range)':
        {
          backgroundColor: 'transparent',
          color: 'gray.800',
          _disabled: {
            color: 'gray.500',
          },
        },
    },
  };
};

export const dateRangeTheme = {
  parts,
  baseStyle,
};
