import numeral from 'numeral';
export const getNumber = (val: string | number | null): number => {
  if (val === null) return 0;
  let num: number | any;
  if (typeof val === 'string') {
    //Make sure any characters but numbers or period are removed
    num = numeral(val.replace(/[^0-9.]/g, '')).value();
  }
  if (isNaN(num)) num = 0;
  else if (typeof val === 'undefined') num = 0;
  if (num === null) return 0;
  else {
    let retval = numeral(val).value();
    if (retval === null) return 0;
    else return retval;
  }
};
