import * as React from 'react';
import {
  forwardRef,
  FormControl as ChakraFormControl,
  FormLabel,
  FormLabelProps,
  FormHelperText,
  FormErrorMessage,
  FormControlProps as ChakraFormControlProps,
  HTMLChakraProps,
} from '@chakra-ui/react';
import { Text } from 'components';

export interface FormControlProps extends Omit<ChakraFormControlProps, 'label'> {
  label?: React.ReactNode | string;
  size?: 'sm' | 'md' | 'lg';
  helpText?: string | React.ReactNode;
  errorText?: string;
  isSuccess?: boolean;
  labelMarginBottom?: string;
  helpTextProps?: HTMLChakraProps<'div'>;
  labelProps?: FormLabelProps;
}

const FormControl: React.FC<FormControlProps> = forwardRef((props, ref) => {
  const {
    label,
    helpText,
    errorText,
    children,
    size = 'md',
    isInvalid,
    isDisabled,
    isSuccess,
    labelMarginBottom,
    helpTextProps,
    labelProps,
    isRequired,
    ...rest
  } = props;

  return (
    <ChakraFormControl isInvalid={isInvalid} ref={ref} {...rest}>
      {label && (
        <FormLabel
          isDisabled={isDisabled}
          mb={labelMarginBottom}
          isTruncated
          size={size}
          {...labelProps}
        >
          {label}
          {isRequired ? (
            <Text color="red.500" marginLeft="4px" display="inline-block">
              *
            </Text>
          ) : (
            ''
          )}
        </FormLabel>
      )}
      {children}
      {!isInvalid && helpText && (
        <FormHelperText isDisabled={isDisabled} {...helpTextProps}>
          {helpText}
        </FormHelperText>
      )}
      {isInvalid && errorText && <FormErrorMessage>{errorText}</FormErrorMessage>}
    </ChakraFormControl>
  );
});

export default FormControl;
