export const primary = {
  50: '#FFFFFF',
  100: '#F6F6F7',
  200: '#B8DBEE',
  300: '#7ABDDF',
  400: '#3D9ED1',
  500: '#0080C2',
  600: '#006CA3',
  700: '#005784',
  800: '#004365',
  900: '#002E46',
};

export const secondary = {
  50: '#FFFFFF',
  100: '#F9F9FA',
  200: '#F6F6F7',
  300: '#F2F3F3',
  400: '#E6E7E8',
  500: '#C6C7C9',
  600: '#94989B',
  700: '#63686C',
  800: '#32383E',
  900: '#2A2F34',
  1000: '#1A1D20',
};

export const accent = {
  50: '#FFFFFF',
  100: '#FEF3E6',
  200: '#FDE1C0',
  300: '#FBC78A',
  400: '#F9AE54',
  500: '#F7941E',
  600: '#CF7C19',
  700: '#A86514',
  800: '#804D10',
  900: '#59350B',
};

export const destructive = {
  50: '#FFFFFF',
  100: '#FBE8E6',
  200: '#F4C5C0',
  300: '#EA9389',
  400: '#E06153',
  500: '#D62F1D',
  600: '#B42718',
  700: '#922014',
  800: '#6F180F',
  900: '#4D110A',
};

export const warning = {
  50: '#FFFFFF',
  100: '#FFF8ED',
  200: '#FFEED2',
  300: '#FEE0AB',
  400: '#FED184',
  500: '#FEC35D',
  600: '#D5A44E',
  700: '#AD853F',
  800: '#846530',
  900: '#5B4621',
};

export const success = {
  50: '#FFFFFF',
  100: '#E4F1ED',
  200: '#BCDBD3',
  300: '#83BDAD',
  400: '#4A9E87',
  500: '#118061',
  600: '#0E6C51',
  700: '#0C5742',
  800: '#094332',
  900: '#062E23',
};

export const dark = {
  50: '#000',
  100: '#000',
  200: '#000',
  300: '#000',
  400: '#000',
  500: '#2A2F34',
  600: '#1A1D20',
  700: '#000',
  800: '#000',
  900: '#000',
};

export const blue = {
  50: '#FFFFFF',
  100: '#F6F6F7',
  200: '#B8DBEE',
  300: '#7ABDDF',
  400: '#3D9ED1',
  500: '#0080C2',
  600: '#006CA3',
  700: '#005784',
  800: '#004365',
  900: '#002E46',
};
export const orange = {
  50: '#FFFFFF',
  100: '#FEF3E6',
  200: '#FDE1C0',
  300: '#FBC78A',
  400: '#F9AE54',
  500: '#F7941E',
  600: '#CF7C19',
  700: '#A86514',
  800: '#804D10',
  900: '#59350B',
};

export const gray = {
  50: '#FFFFFF',
  100: '#F9F9FA',
  200: '#F6F6F7',
  300: '#F2F3F3',
  400: '#E6E7E8',
  500: '#C6C7C9',
  600: '#94989B',
  700: '#63686C',
  800: '#32383E',
  900: '#2A2F34',
  1000: '#1A1D20',
};

export const red = {
  50: '#FFFFFF',
  100: '#FBE8E6',
  200: '#F4C5C0',
  300: '#EA9389',
  400: '#E06153',
  500: '#D62F1D',
  600: '#B42718',
  700: '#922014',
  800: '#6F180F',
  900: '#4D110A',
};

export const yellow = {
  50: '#FFFFFF',
  100: '#FFF8ED',
  200: '#FFEED2',
  300: '#FEE0AB',
  400: '#FED184',
  500: '#FEC35D',
  600: '#D5A44E',
  700: '#AD853F',
  800: '#846530',
  900: '#5B4621',
};

export const green = {
  50: '#FFFFFF',
  100: '#E4F1ED',
  200: '#BCDBD3',
  300: '#83BDAD',
  400: '#4A9E87',
  500: '#118061',
  600: '#0E6C51',
  700: '#0C5742',
  800: '#094332',
  900: '#062E23',
};

export const highlightColor = '#E3F1F8';
