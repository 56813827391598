import React from 'react';
import { Box, Flex, Avatar, Portal, VStack, useMultiStyleConfig } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from 'store';
import {
  Icon,
  Text,
  Dropdown,
  DropdownItem,
  DropdownList,
  DropdownButton,
  Divider,
} from 'components';

export const SidebarUserInfo: React.FC = React.memo(() => {
  const navigate = useNavigate();

  const user = useAuth((state) => state.user);
  const setLogout = useAuth((state) => state.setLogout);

  const styles = useMultiStyleConfig('Sidebar', {});

  return (
    <Flex sx={styles.avatarRow}>
      <Dropdown closeOnSelect={false} matchWidth={true} placement={'bottom'}>
        <DropdownButton sx={styles.dropdownButton}>
          <SidebarUserDropdownButton
            label={user.Name || ''}
            avatar={user.Name || ''}
            helpText={user.Name || ''}
          />
        </DropdownButton>
        <Portal>
          <DropdownList zIndex={25000} w={'inherit'} minW={'auto'}>
            <Box
              flexDir={'column'}
              overflow={'hidden'}
              textOverflow={'ellipsis'}
              whiteSpace={'nowrap'}
              p={'8px'}
              display={'flex'}
            >
              <Text
                variant={'labelSmallSemiBold'}
                overflow={'hidden'}
                textOverflow={'ellipsis'}
                whiteSpace={'nowrap'}
              >
                {user.Name}
              </Text>
              <Text
                color={'gray.700'}
                variant={'labelExtraSmallRegular'}
                overflow={'hidden'}
                textOverflow={'ellipsis'}
                whiteSpace={'nowrap'}
              >
                {'User'}
              </Text>
            </Box>
            <Divider />
            <DropdownItem
              color="red.500"
              borderBottom="2px solid"
              borderBottomColor="gray.400"
              justifyContent="flex-start"
              onClick={() => {
                setLogout(true);
                navigate('/');
              }}
            >
              <Icon variant="Logout" size={6} color="red.500" mr={2} />
              {'Logout'}
            </DropdownItem>
            <DropdownItem color="gray.600">{`Version 11.0.0`}</DropdownItem>
          </DropdownList>
        </Portal>
      </Dropdown>
    </Flex>
  );
});

interface SidebarUserDropdownButtonProps {
  label: string;
  avatar: string;
  helpText: string;
}
const SidebarUserDropdownButton = React.memo((props: SidebarUserDropdownButtonProps) => {
  const { label, avatar, helpText } = props;

  const styles = useMultiStyleConfig('Sidebar', {});

  return (
    <Flex sx={styles.avatar} px={1} h={50} align={'center'}>
      <Flex w={10} justify={'flex-start'}>
        <Avatar name={avatar} borderRadius={5} size="lg" variant="square" />
      </Flex>
      <VStack flex={1} spacing={0} align={'flex-start'} justify={'center'}>
        <Box>
          <Text variant={'labelSmallSemiBold'} noOfLines={1} maxW={'110px'}>
            {label}
          </Text>
        </Box>
        <Box>
          <Text variant={'labelExtraSmallRegular'} noOfLines={1} maxW={'110px'}>
            {helpText}
          </Text>
        </Box>
      </VStack>
    </Flex>
  );
});
