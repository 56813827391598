import { useEffect } from 'react';
import { Grid, InputGroup, GridItem, Checkbox } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

import { Form, Input, InputControl, Select } from 'Library/CRUD';

interface IMerchantEdit {
  id: string;
  crud: any;
}

export const ProviderEdit = (props: IMerchantEdit) => {
  const { id, crud } = props;
  const form = useForm();
  const {
    type,
    auth_url,
    base_url,
    api_key,
    login_required,
    amount_in_cents,
    basic_auth_username,
    basic_auth_password,
    inactive,
  } = form.watch();

  //APPLY CRUD RECORD VALUES TO FORM
  useEffect(() => {
    if (crud && crud.record && Object.keys(crud.record).length) {
      form.reset(crud.record);
    }
    // Override es-lint (We do not want to update everytime other parts of Crud Change)
    // eslint-disable-next-line
  }, [crud.record]);

  const typeSelect = [
    { value: 'BOTH', label: 'BOTH' },
    { value: 'ECOM', label: 'ECOM' },
    { value: 'POS', label: 'POS' },
  ];

  const onSubmit = async () => {
    // e.preventDefaullt()
    const result = await crud.submit(form.getValues());
    if (result && !result.error) crud.set({ active: false });
  };

  return (
    <Form minHeight={550} form={form} crud={crud} id={id} onSubmit={form.handleSubmit(onSubmit)}>
      <Grid gap="8px" templateColumns={{ base: '1fr', lg: '1fr 1fr' }}>
        <GridItem>
          <Input
            form={form}
            name="code"
            label="Code"
            variant="input"
            size="lg"
            readOnly={crud.key}
            placeholder="Enter Merchant Code"
            sx={{ textTransform: 'uppercase' }}
            validation={{
              required: 'Merchant Code is Required',
            }}
          />
        </GridItem>
        <GridItem>
          <InputControl
            id="type"
            form={form}
            label="Type"
            validation={{
              required: 'Type is required',
            }}
            control={
              <InputGroup size="lg">
                <Select
                  name="type"
                  defaultValue={type}
                  options={typeSelect}
                  onChange={(val: any) => {
                    form.setValue('type', val);
                  }}
                  size="lg"
                  placeholder="Select Type..."
                />
              </InputGroup>
            }
          />
        </GridItem>
        <GridItem colSpan={2}>
          <Input
            form={form}
            name="name"
            label="Name"
            variant="input"
            size="lg"
            placeholder="Enter Merchant Name"
            validation={{
              required: 'Merchant Name is Required',
            }}
          />
        </GridItem>
        <GridItem colSpan={2}>
          <Input
            form={form}
            name="base_url"
            label="Providers Base URL"
            variant="input"
            size="lg"
            value={base_url}
            placeholder="Enter Base URL for this Provider"
            validation={{
              required: 'Provider Base URL is Required',
            }}
          />
        </GridItem>
        <GridItem colSpan={2}>
          <Input
            form={form}
            name="auth_url"
            label="Providers Auth URL"
            variant="input"
            size="lg"
            value={auth_url}
            placeholder="Enter Provider Auth URL (if relevant)"
          />
        </GridItem>
        <GridItem colSpan={2}>
          <Input
            form={form}
            name="api_key"
            label="Providers API Key"
            variant="textarea"
            rows={2}
            size="lg"
            value={api_key}
            placeholder="Enter Provider API Key (if relevant)"
          />
        </GridItem>
        <GridItem colSpan={2}>
          <Input
            form={form}
            name="rule"
            label="Vault Rule"
            variant="input"
            size="lg"
            value={api_key}
            placeholder="Enter Vault Rule"
          />
        </GridItem>
        <GridItem colSpan={2}>
          <Checkbox
            isChecked={amount_in_cents}
            size="lg"
            onChange={(e) => {
              form.setValue('amount_in_cents', e.target.checked);
            }}
          >
            Amount is in cents
          </Checkbox>
        </GridItem>
        <GridItem colSpan={2}>
          <Checkbox
            isChecked={login_required}
            size="lg"
            onChange={(e) => {
              form.setValue('login_required', e.target.checked);
            }}
          >
            Login Required
          </Checkbox>
        </GridItem>
        {login_required && (
          <>
            <GridItem>
              <Input
                form={form}
                name="basic_auth_username"
                label="User name"
                variant="input"
                size="lg"
                value={basic_auth_username}
                placeholder="Enter Username"
                validation={{
                  required: 'Username is required',
                }}
              />
            </GridItem>

            <GridItem>
              <Input
                form={form}
                name="basic_auth_password"
                label="password"
                variant="input"
                size="lg"
                value={basic_auth_password}
                placeholder="Enter Password"
                validation={{
                  required: 'Password is required',
                }}
              />
            </GridItem>
          </>
        )}

        <GridItem colSpan={2}>
          <Checkbox
            isChecked={inactive}
            size="lg"
            onChange={(e) => {
              form.setValue('inactive', e.target.checked);
            }}
          >
            Set as Inactive
          </Checkbox>
        </GridItem>
      </Grid>
    </Form>
  );
};
