import React, { useEffect, useMemo } from 'react';
import { Box, Flex, Spacer } from '@chakra-ui/react';

import CRUD, { useCRUD, Table } from 'Library/CRUD';
import { useAuth, useDB } from 'store';
import { Text, Divider, Icon, Alert } from 'components';
import { useAlert } from 'utils';
import moment from 'moment';

const InactiveMerchants = () => {
  const user = useAuth((state) => state.user);

  const [alert, setAlert] = useAlert();

  const crud = useCRUD({
    id: 'transaction',
    title: '',
    hasEditor: false,
    keyField: 'id',
    setAlert: setAlert,
    waitRefresh: true,
    fetch: async (qry: string) => {
      let result = await useDB.getState().axios({
        server: 'PAYGATE',
        method: 'POST',
        url: `/api/v1/analytics/InactiveMerchants/`,
      });
      const inactiveMerchants = result.data.data.map((v: any) => ({
        ...v,
        LastTransactionDate: !moment(v.LastTransactionDate).isBefore("0001-01-02") ?
            moment(v.LastTransactionDate).format('DD/MM/YYYY HH:mm') :
            "N/A"
      }))

      return inactiveMerchants;
    },
  });

  useEffect(() => {
    crud.refresh({
      message: 'CHANGE USER ID',
      filter: { merchant: user.Merchant },
    });
    // eslint-disable-next-line
  }, [user.Merchant]);

  const columns = useMemo(
    () => [
      {
        name: 'Code',
        selector: (row: any) => row['Code'],
        sortable: true,
        width: '120px'
      },
      {
        name: 'Name',
        selector: (row: any) => row['Name'],
        format: (row: any) => <Text>{row.Name}</Text>,
        sortable: true,
      },
      {
        name: 'Last Transaction Date',
        selector: (row: any) => row['LastTransactionDate'],
        format: (row: any) => <Text>{row.LastTransactionDate}</Text>,
        sortable: true,
        width: '200px',
      },
    ],
    [],
  );

  return (
    <Flex
      borderRadius={'4px'}
      border={'1px solid #F2F3F3'}
      bg={'white'}
      p={'24px'}
      flexDir={'column'}
      height={'100%'}
      overflow={'auto'}
    >
      <Flex h={'44px'}>
        <Icon variant={'Clock'} size={11} />
        <Flex flexDir={'column'} ml={'12px'}>
          <Text variant={'heading05Bold'} color={'secondary.800'} mb={'4px'}>
           Merchants Inactive for Last 30 Days 
          </Text>
          <Spacer />
        </Flex>
      </Flex>
      <Divider my={'16px'} variant={'fade'} />
      <Box fontFamily={'Roboto Condensed'} overflow={'hidden'}>
        <CRUD
          id="inactive"
          crud={crud}
        >
          <Alert alert={alert} setAlert={setAlert} />
          <Table
            pagination={false}
            id="bank_table"
            crud={crud}
            columns={columns}
            responsive={true}
            striped={false}
          ></Table>
        </CRUD>
      </Box>
    </Flex>
  );
};

export default React.memo(InactiveMerchants);
