/**********************************************************************************************
 *** getSelect() - Create Select Lookup (for use with react-select) from keyed Object array ***
 **********************************************************************************************
 Paramaters: arr Object array keyed by the id (value) fields and with name as label (any other values are placed in props)
 Options: orderBy ['code/name','asc/desc'] default [code/asc]
          orderDirection: ('asc' or 'desc')
 */

import _get from 'lodash/get';
import _orderBy from 'lodash/orderBy';

interface IGetSelectOptions {
  keyField: string;
  label: string; //Must be code or name
  orderBy?: string[];
  selectAll?: any;
}

export const getSelect = (arr: any, opts?: IGetSelectOptions) => {
  let keyField = _get(opts, 'keyField', 'code');
  let labelField = opts && opts.label ? opts.label : 'name';
  let keys = Object.keys(arr);
  let select: any = [];
  if (opts && opts.selectAll) {
    if (typeof opts.selectAll === 'boolean') select.push({ value: '', label: 'Select All ...' });
    else select.push(opts.selectAll);
  }
  keys.forEach((key) => {
    select.push({ value: key, label: _get(arr, `${key}.${labelField}`, key) });
  });

  if (opts && opts.orderBy) {
    //Order By Acccording to
    let args1: any = [_get(opts, 'orderBy[0]', keyField)];
    let args2: any = [_get(opts, 'orderBy[1]', 'desc')];
    select = _orderBy(select, args1, args2);
  }

  return select;
};
