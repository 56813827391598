import React from 'react';
import { Box, Grid, GridItem } from '@chakra-ui/react';

import { Header } from 'Library';
import { useAuth } from 'store';
import { TopMerchants, TotalRevenue } from './AdminDashboard';
import InactiveMerchants from './AdminDashboard/InactiveMerchants';

export const Dashboard = React.memo(() => {
  const mode = useAuth((state) => state.user?.Mode);
  const dayRange = 30;

  return (
    <Box>
      {mode !== 'API' && <Header headerText="Dashboard" />}
      <Grid p={2} gap="8px" templateColumns={{ base: '1fr', lg: '1fr 1fr' }}>
        <GridItem>
            <TotalRevenue /> 
        </GridItem>
        <GridItem>
            <TopMerchants dayRange={dayRange} />
        </GridItem>
        <GridItem>
          <InactiveMerchants />
        </GridItem>
      </Grid>
    </Box>
  );
});
