import { useEffect } from 'react';
import { Box } from '@chakra-ui/react';

import { useDB } from 'store';
import { Text, Modal } from 'components';

import { ProgressIndicator } from './ProgressIndicator';

export const Progress = (props) => {
  const { variant, progress, modalProps, title, okProps, cancelProps, children, textProps, ...o } =
    props;

  const watchAPI = async () => {
    try {
      setTimeout(async () => {
        let result = await useDB.getState().axios({
          method: 'GET',
          url: `/api/v1/progress/${progress.id}`,
          noSpinner: true,
        });
        progress.set(result);
        if (result.percent < 100) watchAPI();
        else {
          if (progress.autoclose) {
            progress.set({ percent: 100 });
            setTimeout(() => progress.set({ active: false }), 1000);
          }
          if (progress.callback) progress.callback(progress);
        }
      }, 1000);
    } catch (e) {
      console.error('Error in WatchAPI', e);
      progress.set({ percent: 100, active: false });
    }
  };

  useEffect(() => {
    if (progress.active) {
      watchAPI();
    } else {
      progress.set({ percent: 100 });
      useDB.getState().setLoading(false);
      setTimeout(() => progress.set({ active: false }), 1000);
      useDB.getState().setLoading(false);
    }
    // eslint-disable-next-line
  }, [progress.active]);

  if (variant === 'inline') {
    if (progress.percent >= 100 && !progress.active) return <></>;
    else
      return (
        <Box {...o}>
          <ProgressIndicator percent={progress.percent} />
        </Box>
      );
  } else {
    return (
      <Modal
        title={title || 'Progress'}
        okProps={okProps || { sx: { display: 'none' } }}
        cancelProps={cancelProps || { sx: { display: 'none' } }}
        isOpen={progress.active}
        onClose={() => progress.set({ active: false })}
      >
        {progress.message ? <Text {...textProps}>{progress.message}</Text> : <></>}
        <Box {...o}>
          <ProgressIndicator percent={progress.percent} />
        </Box>
      </Modal>
    );
  }
};
