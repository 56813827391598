import { useState, useMemo } from 'react';
//import { Select } from 'chakra-react-select';
import { FormControl } from '@chakra-ui/react';

import { Select } from 'components';
//import { _findBy } from 'lodash/findBy'

export const FilterInputSelect = (props) => {
  const { options: optionsProp, defaultValue, onChange, ...o } = props;
  const [value, setValue] = useState(null);
  //useEffect(() => {
  ////Set Object Label / Value based on just value supplied as defaultValue
  //let opt = optionsProp.filter((rec) => rec.value === defaultValue);
  //if (opt) setValue(opt);
  //}, [defaultValue, optionsProp]);

  const options = useMemo(
    () =>
      optionsProp.map((o) => (
        <option key={o.value} value={o.value}>
          {o.label}
        </option>
      )),
    [optionsProp],
  );

  return (
    <FormControl p={'4px'}>
      <Select
        {...o}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
          setValue(e.target.value);
        }}
        size="md"
      >
        {options}
      </Select>
    </FormControl>
  );
};
