import * as React from 'react';
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalProps as ChakraModalProps,
  ButtonProps,
  ModalBodyProps,
  ModalFooterProps,
  ModalContentProps,
  Box,
  HStack,
  BoxProps,
} from '@chakra-ui/react';

import { ButtonVariants, ButtonSizes } from 'theme/components';
import { Button } from 'components';

import { ModalHeader, ModalHeaderProps } from './Atoms';

interface ModalButtonProps extends Omit<ButtonProps, 'variant'> {
  variant?: keyof ButtonVariants;
  size?: keyof ButtonSizes;
  label?: string;
}

export interface ModalProps extends Omit<ChakraModalProps, 'size'> {
  /**
   * Modal size
   * @default md
   */
  size?: 'sm' | 'md' | 'lg';
  /**
   * This will appear in left side of Header
   */
  title?: string | React.ReactNode;
  /**
   * This will show in top of Title
   */
  subTitle?: string | React.ReactNode;
  /**
   * This will appear in right side of Header
   */
  headerActions?: Array<React.ReactNode>;
  /**
   * Use `titleProps` to customize **title**. <br />
   * Use `subTitleProps` to customize **subTitle**. <br />
   * `titleProps` and `subTitleProps` accepts any valid text/css props. <br />
   * You can also customize Header Container by using any valid css props.
   */
  headerProps?: ModalHeaderProps;
  /**
   * OnClick Event for Back Button
   */
  onBack?: () => void;
  /**
   * Custom Text for Ok Button
   */
  okText?: string;
  /**
   * Customize Ok Button by providing any valid Button props.
   * Check Button stories for reference
   * @default Ok
   */
  okProps?: ModalButtonProps;
  /**
   * OnClick Event for Ok Button
   */
  onOk?: () => void;
  /**
   * Custom Text for Cancel Button
   */
  cancelText?: string;
  /**
   * Customize Cancel Button by providing any valid Button props.
   * Check Button stories for reference
   * @default Cancel
   */
  cancelProps?: ModalButtonProps;
  /**
   * OnClick Event for Cancel Button
   */
  onCancel?: () => void;
  /**
   * use this to customize the modal content styles like boxShadow, background etc.
   */
  contentProps?: ModalContentProps;
  /**
   * use this to customize the modal body styles
   */
  bodyProps?: ModalBodyProps;
  /**
   * This will appear in left side of footer
   */
  footerActions?: Array<React.ReactNode>;
  /**
   * Use this to cutomize the Footer Container.
   */
  footerProps?: ModalFooterProps;
  /**
   * hide footer
   */
  isFooterHidden?: boolean;
  minHeight?: string | number;
  height?: string | number;
  maxHeight?: string | number;
  StickyElementTop?: React.ReactElement;
  StickyElementBottom?: React.ReactElement;
  background?: string;
  /**
   * modalSpaceProp
   * modify the modalSpace
   */
  modalSpace?: string | number;
  /**
   * chakra-modal-content-left
   */
  chakraModalContentLeft?: string | number;
  sidebarOverlay?: boolean;
  boxProps?: BoxProps;

  cancelButton?: React.ReactElement;
  okButton?: React.ReactElement;
}

const Modal: React.FC<ModalProps> = (props) => {
  const {
    title,
    subTitle,
    headerActions,
    headerProps,
    size = 'md',
    onBack,
    okText = 'Ok',
    onOk,
    okProps,
    cancelText = 'Cancel',
    onCancel,
    onClose,
    cancelProps,
    contentProps,
    children,
    bodyProps,
    footerProps,
    footerActions,
    isFooterHidden,
    minHeight,
    height,
    maxHeight,
    StickyElementTop,
    StickyElementBottom,
    background,
    modalSpace: modalSpaceProp = '220px',
    chakraModalContentLeft: chakraModalContentLeftProp,
    sidebarOverlay,
    cancelButton,
    okButton,
    boxProps,
    ...rest
  } = props;

  const modalSpace = modalSpaceProp;

  return (
    <ChakraModal isCentered size={size} autoFocus={false} onClose={onClose} {...rest}>
      <ModalOverlay left={sidebarOverlay ? 0 : modalSpace} />
      <Box
        __css={{
          '.chakra-modal__content-container': {
            width: `calc(100% - ${modalSpaceProp || modalSpace})`,
            left: chakraModalContentLeftProp || modalSpace,
          },
        }}
        {...boxProps}
      >
        <ModalContent
          background={background}
          minHeight={minHeight}
          height={height}
          maxHeight={maxHeight}
          my={0}
          {...contentProps}
        >
          <ModalHeader
            title={title}
            subTitle={subTitle}
            actions={headerActions}
            onBack={onBack}
            {...headerProps}
          />
          {StickyElementTop}
          <ModalBody {...bodyProps}>{children}</ModalBody>
          {StickyElementBottom}
          {!isFooterHidden && (
            <ModalFooter {...footerProps}>
              {footerActions && (
                <HStack mr="auto" spacing="8px">
                  {footerActions.map((action, index) => {
                    if (action) {
                      return <Box key={index}>{action}</Box>;
                    }
                    return null;
                  })}
                </HStack>
              )}
              <Box>
                {cancelButton || (
                  <Button
                    label={cancelText}
                    colorScheme="secondary"
                    mr="8px"
                    onClick={onCancel ? onCancel : onClose}
                    {...cancelProps}
                  />
                )}
                {okButton || (
                  <Button label={okText} colorScheme="primary" onClick={onOk} {...okProps} />
                )}
              </Box>
            </ModalFooter>
          )}
        </ModalContent>
      </Box>
    </ChakraModal>
  );
};

export default Modal;
