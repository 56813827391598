import create from 'zustand';
import { persist } from 'zustand/middleware';

export interface ILookup {
  Merchant: any[];
  Provider: any[];
  Rateplan: any[];
  CardType: any[];
  RateClass: any[];
  Status: any;
  TranType: any;
}
type IUseStoreState = {
  lookup: ILookup;
  setLookup: (id: keyof ILookup, arr: any[]) => void;
};

export const useStore = create<IUseStoreState>()(
  persist(
    (set) => ({
      lookup: {
        Merchant: [],
        Provider: [],
        Rateplan: [],
        CardType: [],
        RateClass: [],
        Status: '',
        TranType: '',
      },
      setLookup: (id, arr) => {
        set((state) => ({ lookup: { ...state.lookup, [id]: arr } }));
      },
    }),
    {
      name: 'paygate-store',
      getStorage: () => sessionStorage,
    },
  ),
);
