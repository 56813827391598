import React, { useEffect, useMemo, useState, useCallback } from 'react';
import numeral from 'numeral';
import moment from 'moment';
import 'moment-timezone';
import { Box, Flex, Text, Tooltip, Link } from '@chakra-ui/react';
import {
  FaCcVisa,
  FaCcMastercard,
  FaCcAmex,
  FaCcDinersClub,
  FaCreditCard,
} from 'react-icons/fa';
import _get from 'lodash/get';
import { uniq, orderBy } from 'lodash';

import CRUD, {
  useCRUD,
  Table,
  FilterInputSearch,
  FilterInputDateRange,
  FilterInputSelect,
  Filters,
  BtnRefresh,
  MultiLineHeader,
  NumberFormat,
} from 'Library/CRUD';
import { getSelect, getNumber } from 'Library';
import { useStore, useAuth, useDB } from 'store';
import { Alert, Modal } from 'components';
import { useAlert, useDownloadPDF } from 'utils';

import TransactionDrilldown from './TransactionDrilldown';
import TransactionSourceIcon from 'components/Icons/TransactionSourceIcon';
import ReportsPDFModal from 'routes/Reports/ReportsPDFModal';

const CardType = (props: { card: string }) => {
  const { card } = props;
  switch (card) {
    case 'VISA':
      return <FaCcVisa size="25px" />;
    case 'MCARD':
      return <FaCcMastercard size="25px" />;
    case 'AMEX':
      return <FaCcAmex size="25px" />;
    case 'DINER':
      return <FaCcDinersClub size="25px" />;
    default:
      return <FaCreditCard size="25px" />;
  }
};

const SourceType = (props: { source: string }) => {
  const { source } = props;
  const sourceLabel = source?.charAt(0).toUpperCase() + source.slice(1);
  let sourceIcon;
  switch (source) {
    case 'terminal':
      sourceIcon = <TransactionSourceIcon variant="terminal" size={'25px'} />;
      break;
    default:
      sourceIcon = <TransactionSourceIcon variant="online" size={'20px'} />;
      break;
  }
  return (
    <Tooltip label={sourceLabel} placement="right">
      <Box>{sourceIcon}</Box>
    </Tooltip>
  );
};

const spaced = (str: string | null) => {
  if (!str) return '';

  let spacedStr = '';
  for (let i = 0; i < str.length; i += 4) {
    spacedStr += `${str.substr(i, 4)} `;
  }
  return spacedStr.trim();
};

const Variance = (props: any) => {
  let row: any = props.row;
  let variance = 0;
  if (getNumber(row.expected_fees) !== 0) {
    variance =
      getNumber(row.expected_fees - getNumber(row.settle_fees)) / getNumber(row.expected_fees);
  }

  let textProps: any = {};
  if (variance >= 0) textProps.color = 'green';
  else if (variance > -0.1) textProps.color = 'orange';
  else {
    textProps.color = 'red';
    textProps.fontWeight = 'bold';
  }

  return (
    <Text {...textProps}>
      {variance ? numeral(variance).format('%0.00') : <></>}
    </Text>
  );
};

interface ITransactions {
  options?: any;
}

const Transactions = (props: ITransactions) => {
  let options = _get(props, 'options.transactionFilter', {});

  const db = useDB();
  const user = useAuth((state) => state.user);
  const multiMerchant = user.Type === 'ADMIN' ? true : false;

  const lookup = useStore((state) => state.lookup);
  const [alert, setAlert] = useAlert();
  const [typeSelect, setTypeSelect] = React.useState([]);
  const [statusSelect, setStatusSelect] = React.useState([]);

  const merchantSelect = [{ value: '', label: 'All Merchants' }].concat(
    Object.keys(lookup.Merchant).sort().map((m: any) => {
      return (
        {
          value: lookup.Merchant[m].Code,
          label: lookup.Merchant[m].Code + " - " + lookup.Merchant[m].Name
        }
      )
    })
  )

  const {downloadPdf, showNoPdf, isOpen, fileUrl, fileName, onClose, setShowNoPdf} = useDownloadPDF();

  useEffect(() => {
    if (lookup && lookup.TranType) {
      setTypeSelect(
        getSelect(lookup.TranType, {
          keyField: 'Code',
          label: 'Name',
          selectAll: { value: '', label: 'All Types' },
        }),
      );
    }
    if (lookup && lookup.Status) {
      setStatusSelect(
        getSelect(lookup.Status, {
          keyField: 'Code',
          label: 'Name',
          selectAll: { value: '', label: 'All Status' },
        }),
      );
    }
  }, [lookup]);

  const propertyID = Object.keys(lookup.Merchant)?.[0];
  const timezone = lookup.Merchant?.[propertyID as any]?.Timezone;

  const customSearchFilters = useCallback((searchText: string, transactionItem: any) => {
    if (transactionItem.CardInfo?.CardHolder?.toLowerCase().includes(searchText)) {
      return true;
    }
    if (transactionItem.OriginalAmount?.includes(searchText)) {
      return true;
    }
    if (transactionItem.Reference?.includes(searchText)) {
      return true;
    }
    return false;
  }, []);

  const crud = useCRUD({
    id: 'transaction',
    title: user.Mode === 'API' ? '' : 'Transactions',
    hasEditor: false,
    keyField: 'Code',
    setAlert: setAlert,
    customSearchFilters,
    filter: {
      startDate: moment().subtract(28, 'days').toDate(),
      endDate: moment().toDate(),
      type: '',
      merchant: '',
      status: '',
      ...options,
    },
    fetch: async (qry: string) => {
      const result = await useDB.getState().axios({
        server: 'PAYGATE',
        method: 'GET',
        url: `/api/v1/transactions${qry || ''}`,
      });

      if (result.data.data === null) {
        return []
      }

      const arr = result.data.data.map((v: any) => (
        v === null || v.Details === null ? {
          ...v,
          SettledFees: null,
          TypesString: null,
          Types: null,
          Status: null,
          StatusString: null,
          Date: !!timezone ? moment.utc(v.Date, 'YYYY-MM-DD[T]HH:mm:ss').tz(timezone).format('DD/MM/YYYY HH:mm')
            : moment(v.Date).format('DD/MM/YYYY HH:mm'),
          SortDate: timezone
            ? new Date(moment.utc(v.Date).tz(timezone).format('MM/DD/YYYY'))
            : new Date(moment(v.Date).format('MM/DD/YYYY')),
        } :
          {
            ...v,
            SettledFees: v.Details?.map(
              (d: any) => Number(d.Amount || 0) - Number(d.SettledAmount || 0),
            ).reduce((a: number, b: number) => a + b, 0),
            TypesString: v.Details?.map((d: any) => d.TransactionType).reduce(
              (a: string, b: string) => (a ? `${a},${b}` : b),
              '',
            ),
            Types: uniq<string>(v.Details?.map((d: any) => d.TransactionType)),
            Status: uniq<string>(v.Details?.map((d: any) => d.Status)),
            StatusString: uniq<string>(v.Details?.map((d: any) => d.Status)).reduce(
              (a: string, b: string) => (a ? `${a},${b}` : b),
              '',
            ),
            Date: !!timezone
              ? moment.utc(v.Date, 'YYYY-MM-DD[T]HH:mm:ss').tz(timezone).format('DD/MM/YYYY HH:mm')
              : moment(v.Date).format('DD/MM/YYYY HH:mm'),
            SortDate: timezone
              ? new Date(moment.utc(v.Date).tz(timezone).format('MM/DD/YYYY'))
              : new Date(moment(v.Date).format('MM/DD/YYYY')),
          }));

      return orderBy(arr, ['SortDate'], ['desc']);
    },
  });
  const { updateFilter, refresh } = crud;

  const applyFees = React.useCallback(
    async (id: string) => {
      let result = await useDB.getState().axios({
        server: 'PAYGATE',
        method: 'POST',
        url: `/api/vi/transactions/fees`,
        data: {
          id: id,
        },
      });
      crud.refresh({ message: 'Transaction Apply Fees' });
      return result;
    },
    [crud],
  );

  //FILTER ON CHANGE OF USER MERCHANT CODE (IF LOGIN USER CHANGES)
  useEffect(() => {
    if (user.Merchant && crud.filter.merchant !== user.Merchant) {
      updateFilter({ merchant: user.Merchant });
    }
  }, [updateFilter, user.Merchant, crud.filter.merchant]);

  //REFRESH CRUD IF THERE IS A CHANGE IN DB TOKEN (CAN HAPPEN WITH EMBEDDED CLIENT)
  useEffect(() => {
    if (user.Mode === 'API') refresh({ message: 'Transaction Token' });
  }, [refresh, db.token, user.Mode]);

  let columns = useMemo(
    () => [
      {
        name: 'Date',
        selector: (row: any) => row['Date'],
        width: '150px',
        sortable: true,
      },
      {
        name: 'Merchant',
        selector: (row: any) => row['Merchant'],
        sortable: true,
        width: '90px',
      },
      {
        name: 'Source',
        selector: (row: any) => row['Source'],
        format: (row: any) => <SourceType source={row.Source} />,
        sortable: false,
        width: '60px',
        center: true,
      },
      {
        name: 'Status',
        selector: (row: any) => row['StatusString'],
        sortable: true,
        width: '130px',
      },
      {
        name: 'Type(s)',
        selector: (row: any) => row['TypesString'],
        sortable: true
      },
      {
        name: 'Tran Ref',
        selector: (row: any) => (row.provider === 'PAYRIX' ? row['id'] : row['Reference']),
        format: (row: any) => {
          if (row.Source === 'terminal') return <Link color='teal.500' onClick={() => downloadPdf(row['Reference'])} variant='link'>{row['Reference']}</Link>;
          return <>{row['Reference']}</>;
        },
        sortable: true,
        width: '130px',
      },
      {
        name: 'Reservation #',
        selector: (row: any) => row['ReservationNumber'],
        sortable: true,
        width: '140px',
      },
      {
        name: 'Card',
        selector: (row: any) => row['CardInfo'].CardTypeCode,
        format: (row: any) => <CardType card={row.CardInfo.CardTypeCode} />,
        sortable: false,
        width: '45px',
        center: true,
      },
      {
        name: 'Card Holder',
        selector: (row: any) => row['CardInfo'].CardHolder,
        sortable: true,
        width: '180px',
      },
      {
        name: 'Card',
        selector: (row: any) => row['CardInfo'].Mask,
        format: (row: any) => (row.CardInfo.Mask ? spaced(row.CardInfo.Mask) : ''),
        sortable: false,
        width: '150px',
      },
      {
        name: 'Amount',
        selector: (row: any) => row['OriginalAmount'],
        sortable: false,
        format: (row: any) => <NumberFormat amount={row.OriginalAmount} />,
        width: '80px',
        right: true,
      },
      {
        name: <MultiLineHeader heading={['Expected', 'Fees']} align="right" />,
        selector: (row: any) => row['expected_fees'],
        sortable: false,
        format: (row: any) => <NumberFormat amount={row.expected_fees} />,
        width: '80px',
        right: true,
        omit: user.Mode === 'API' || user.Type !== 'ADMIN',
      },
      {
        name: <MultiLineHeader heading={['Variance', '%']} align="right" />,
        selector: (row: any) => row['fees'],
        sortable: false,
        format: (row: any) => <Variance row={row} />,
        width: '80px',
        right: true,
        omit: user.Mode === 'API' || user.Type !== 'ADMIN',
      },
      {
        name: <MultiLineHeader heading={['Net', 'Value']} align="right" />,
        selector: (row: any) => row['net'],
        sortable: false,
        format: (row: any) => <NumberFormat amount={row.net - row.settle_fees} />,
        width: '80px',
        right: true,
        omit: user.Mode === 'API' || user.Type !== 'ADMIN',
      },
    ],
    [user.Mode, user.Type, downloadPdf],
  );

  const [customFilter, setCustomFilter] = useState<{
    date: null | ((arg: any[] | null) => void);
    type: null | ((arg: any[] | null) => void);
    status: null | ((arg: any[] | null) => void);
  }>({
    date: (arr: any[] | null) => {
      if (!arr) return arr;
      return arr.filter((v) =>
        moment(v.Date, 'DD/MM/YYYY HH:mm').isBetween(
          moment().subtract(28, 'days').toDate(),
          moment().toDate(),
          'days',
          '[]',
        ),
      );
    },
    type: null,
    status: null,
  });

  const filters = useMemo(() => {
    return (
      <Filters
        templateColumns={[
          '1fr',
          '1fr 1fr',
          '1fr 1fr',
          '1fr 1fr',
          `300px 260px ${multiMerchant ? '200px' : ''} 170px 280px`,
        ]}
      >
        <FilterInputSearch crud={crud} />
        <FilterInputDateRange
          value={{
            from: moment(crud.filter.startDate).toDate(),
            to: moment(crud.filter.endDate).toDate(),
          }}
          onChange={(dates: any) => {
            updateFilter({
              startDate: dates[0],
              endDate: dates[1],
            });
            setCustomFilter((prev) => ({
              ...prev,
              date: (arr: any[] | null) => {
                if (!arr) return arr;
                return arr.filter((v) =>
                  moment(v.Date, 'DD/MM/YYYY HH:mm').isBetween(
                    moment(dates[0]).toDate(),
                    moment(dates[1]).toDate(),
                    'days',
                    '[]',
                  ),
                );
              },
            }));
          }}
        />
        {multiMerchant && (
          <FilterInputSelect
            placeholder="Merchant..."
            defaultValue={crud.filter.merchant}
            width="100%"
            onChange={(val: string) => {
              updateFilter({ merchant: val });
            }}
            options={merchantSelect}
          />
        )}
        <FilterInputSelect
          placeholder="Select Status"
          value={crud.filter.status || ''}
          width="100%"
          onChange={(val: string) => {
            //updateFilter({ status: val });
            setCustomFilter((prev) => ({
              ...prev,
              status: (arr: any[] | null) => {
                if (!val || !arr) return arr;
                return arr.filter((v) => 
                  !v?.Status ? false : v.Status.includes(val));
              },
            }));
          }}
          options={statusSelect}
        />
        <Flex>
        <FilterInputSelect
          placeholder="Select Type"
          value={crud.filter.type || ''}
          width="100%"
          onChange={(val: string) => {
            setCustomFilter((prev) => ({
              ...prev,
              type: (arr: any[] | null) => {
                if (!val || !arr) return arr;
                return arr.filter((v) =>
                  !v?.Types ? false : v.Types.includes(val));
              },
            }));
          }}
          options={typeSelect}
        />
          <Box p={'4px'}>
            <BtnRefresh crud={crud} cascade={true} />
          </Box>
        </Flex>
      </Filters>
    );
  }, [crud, merchantSelect, multiMerchant, typeSelect, statusSelect, updateFilter]);

  const conditionalRowStyles = [
    {
      when: (row: any) => row.type === 'PAYMENT' && row.settled > 0,
      style: {
        color: 'green',
        backgroundColor: '#f3f8f3',
        fontWeight: 600,
      },
    },
    {
      when: (row: any) => row.type === 'PREAUTH' && row.net > 0,
      style: {
        color: 'purple',
        backgroundColor: '#f6f1f6',
        fontWeight: 600,
      },
    },
  ];

  return (
    <Box fontFamily={'Public Sans'} lineHeight={1.2}>
      <CRUD id="transactions" crud={crud} filters={filters}>
        <Alert alert={alert} setAlert={setAlert} />
        <Table
          id="bank_table"
          crud={crud}
          columns={columns}
          responsive
          expandableRows
          customFilter={customFilter}
          expandableRowDisabled={(row: any) => row.disabled}
          expandableRowsComponent={TransactionDrilldown}
          expandableRowsComponentProps={{
            pl: '45px',
            filter: crud.filter,
            refreshParent: crud.refresh,
            applyFees: applyFees,
          }}
          conditionalRowStyles={conditionalRowStyles}
          striped={false}
        ></Table>
      </CRUD>
      {isOpen &&
        <ReportsPDFModal
          fileUrl={fileUrl}
          fileName={fileName}
          isOpen={isOpen}
          onClose={onClose}
          title='Terminal Receipt'
        />
      }
      {
        showNoPdf && <Modal
          isOpen={showNoPdf}
          onClose={() => setShowNoPdf(false)}
          children={<div>There is no receipt data available for this transaction</div>}
          cancelText={'Close'}
          okProps={{ display: 'none' }}
          size={'sm'}
        />
      }
    </Box>
  );
};

export default React.memo(Transactions);
