/*********************************************
 *** Form - Form Component for Crud Editor ***
 *********************************************
 Note: This has a fieldset for readonly mode and also a hidden submit button (chich can be called by outer submit button)
 */

import React, { useRef, useEffect } from 'react';
import { VisuallyHidden, Box } from '@chakra-ui/react';
import _get from 'lodash/get';

interface FormProps {
  id: string;
  crud: any;
  children?: React.ReactNode;
  [x: string]: any;
}

export const Form: React.FC<FormProps> = ({ id, crud, children, ...o }) => {
  const ref = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (ref && ref.current) crud.set({ submitRef: ref.current });
    // eslint-disable-next-line
  }, [ref]);
  return (
    <Box {...o}>
      <form id={id}>
        <fieldset disabled={_get(crud, 'record._readonly', false)}>
          {children}
          <VisuallyHidden>
            <button ref={ref} id={`${id}-submit-button`} type="submit">
              HIDDEN SUBMIT
            </button>
          </VisuallyHidden>
        </fieldset>
      </form>
    </Box>
  );
};
