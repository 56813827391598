import React from 'react';
import {
  Box,
  forwardRef,
  BoxProps,
  useMultiStyleConfig,
  StylesProvider,
  SystemProps,
  HTMLChakraProps,
} from '@chakra-ui/react';

import { CardHead } from './CardHead';
import { CardHeadInner } from './CardHeadInner';
import { CardTitle } from './CardTitle';
import { CardDescription } from './CardDescription';
import { CardRightActions } from './CardRightActions';
import { CardLeftActions } from './CardLeftActions';
import { CardLeftButton } from './CardLeftButton';
import { CardRightButton } from './CardRightButton';
import { CardBody } from './CardBody';

export interface CardProps extends Omit<BoxProps, 'title'> {
  /**
   * border variant still uses shadow but has spread of 1px to mimic the border
   * @default default
   */
  variant?: 'default' | 'header' | 'summary';
  /**
   * card padding and cardTitle will depend on the size
   */
  size?: 'sm' | 'md' | 'lg';
  /**
   * will change icon, border, bg color
   * default white
   */
  colorScheme?: 'white' | 'blue' | 'orange' | 'red' | 'yellow' | 'green';
  /**
   * header title
   */
  title?: React.ReactNode;
  /**
   * header description, will show under title
   */
  description?: React.ReactNode;
  /**
   * header actions, will show left part of header
   */
  leftActions?: React.ReactNode;
  /**
   * custom action item spacing for left Actions
   */
  leftActionsSpacing?: SystemProps['margin'];
  /**
   * header actions, will show right part of header
   */
  rightActions?: React.ReactNode;
  /**
   * custom action item spacing for right Actions
   */
  rightActionsSpacing?: SystemProps['margin'];
  /**
   * custom action item spacing
   */
  actionsSpacing?: SystemProps['margin'];
  /**
   * this will show in left header.
   * Its like leftActions but with no spacing and will remove the card right padding
   */
  leftButtonIcon?: React.ReactNode;
  /**
   * this will show in right header.
   * Its like rightActions but with no spacing and will remove the card right padding
   */
  rightButtonIcon?: React.ReactNode;
  /**
   * right button click event
   */
  onLeftButtonClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  /**
   * rigjt button click event
   */
  onRightButtonClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isBordered?: boolean;
  /**
   * add borderBottom in Header
   */
  isHeaderBordered?: boolean;
  /**
   * will add 4px horizontal bar indicator in top of card
   */
  isActive?: boolean;
  /**
   * custom gutter for Card
   */
  space?: SystemProps['padding'];
  /**
   * custom head styles
   */
  headProps?: any;
  /**
   * custom card head styles
   */
  cardHeadInnerProps?: BoxProps;
  /**
   * custom title styles
   */
  titleProps?: BoxProps;
  /**
   * custom description styles
   */
  descriptionProps?: BoxProps;
  /**
   * custmize left button
   */
  rightButtonProps?: HTMLChakraProps<'button'>;
  /**
   * custmize left button
   */
  leftButtonProps?: HTMLChakraProps<'button'>;
  /**
   * custom body styles
   */
  bodyProps?: BoxProps;
  /**
   * allow other props when using as
   */
  [propName: string]: any;
}

export const Card = forwardRef((props: CardProps, ref) => {
  const {
    variant = 'default',
    size = 'md',
    colorScheme = 'white',
    children,
    title,
    description,
    rightActions,
    rightActionsSpacing,
    leftActions,
    leftActionsSpacing,
    rightButtonIcon,
    leftButtonIcon,
    actionsSpacing,
    space,
    isBordered,
    isActive,
    isHeaderBordered,
    headProps,
    cardHeadInnerProps,
    titleProps,
    leftButtonProps,
    rightButtonProps,
    descriptionProps,
    bodyProps,
    onLeftButtonClick,
    onRightButtonClick,
    ...rest
  } = props;
  const hasHead =
    title || description || leftActions || rightActions || leftButtonIcon || rightButtonIcon;
  const hasBody = children ? true : false;
  const hasLeftButton = leftButtonIcon ? true : false;
  const hasRightButton = rightButtonIcon ? true : false;
  const styles = useMultiStyleConfig('Card', {
    variant,
    size,
    colorScheme,
    space,
    isBordered,
    isActive,
    isHeaderBordered,
    hasHead,
    hasBody,
    hasLeftButton,
    hasRightButton,
  });

  return (
    <Box ref={ref} sx={styles.card} {...rest}>
      <StylesProvider value={styles}>
        {hasHead && (
          <CardHead {...headProps}>
            {leftButtonIcon && (
              <CardLeftButton {...leftButtonProps} onClick={onLeftButtonClick}>
                {leftButtonIcon}
              </CardLeftButton>
            )}
            {leftActions && (
              <CardLeftActions spacing={actionsSpacing || leftActionsSpacing || '8px'}>
                {leftActions}
              </CardLeftActions>
            )}
            <CardHeadInner {...cardHeadInnerProps}>
              {title && <CardTitle {...titleProps}>{title}</CardTitle>}
              {description && (
                <CardDescription {...descriptionProps}>{description}</CardDescription>
              )}
            </CardHeadInner>
            {rightActions && (
              <CardRightActions spacing={actionsSpacing || rightActionsSpacing || '8px'}>
                {rightActions}
              </CardRightActions>
            )}
            {rightButtonIcon && (
              <CardRightButton {...rightButtonProps} onClick={onRightButtonClick}>
                {rightButtonIcon}
              </CardRightButton>
            )}
          </CardHead>
        )}
        <CardBody {...bodyProps}>{children}</CardBody>
      </StylesProvider>
    </Box>
  );
});
