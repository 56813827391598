import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import PropTypes from 'prop-types';

import { Modal } from 'components';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import './viewer.css';

const ReportViewer = (props) => {
  let {
    id,
    title,
    variant,
    selector,
    report,
    url,
    data,
    selections,
    modalProps,
    viewerProps,
    periodSelect,
    btnProps,
    canvas,
    scale,
    renderPage,
    height,
    mode,
    type,
    ...o
  } = props;
  const defaultLayoutPluginInstance = defaultLayoutPlugin(); //Important!

  let reportComponent = (
    <>
      {report.url && (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.0.279 /build/pdf.worker.js">
          <Viewer
            fileUrl={report.url}
            defaultScale={1}
            renderPage={renderPage}
            plugins={[defaultLayoutPluginInstance]}
            {...viewerProps}
            {...o}
          />
        </Worker>
      )}
    </>
  );

  return (
    <Modal
      size="lg"
      isOpen={report.active}
      onClose={() => report.setActive(false)}
      title={report.title}
      okProps={{ display: 'none' }}
      cancelProps={{ display: 'none' }}
    >
      {reportComponent}
    </Modal>
  );
};

ReportViewer.propTypes = {
  id: PropTypes.string.isRequired,
  //Note Compact Button is Acheived by passing compact: true to
  variant: PropTypes.oneOf(['MODAL', 'INLINE']).isRequired,
  periodSelect: PropTypes.bool,
  url: PropTypes.string.isRequired,
  selections: PropTypes.object,
  selector: PropTypes.bool,
  active: PropTypes.bool,
  type: PropTypes.string, //Allows passing of item_selection (Selected Value)
  report: PropTypes.object,
};

ReportViewer.defaultProps = {
  id: 'report-viewer',
  variant: 'MODAL',
  url: '',
  periodSelect: true,
  selections: {},
  selector: false, //Selector will diplay report selection Select Control
};

export default ReportViewer;
