import { SystemStyleObject, ThemeThunk } from '@chakra-ui/styled-system';
import { getColor } from '@chakra-ui/theme-tools';

type Options<T extends string> = Record<T, ThemeThunk<SystemStyleObject>>;
interface TooltipTheme {
  baseStyle: ThemeThunk<SystemStyleObject>;
  variants: Options<keyof TooltipVariants>;
}

export interface TooltipVariants {
  transparent: ThemeThunk<SystemStyleObject>;
  show: ThemeThunk<SystemStyleObject>;
}

const tooltipTheme: TooltipTheme = {
  baseStyle: (props) => {
    const { theme } = props;
    const arrowColor = getColor(theme, 'gray.800');

    return {
      height: 'fit-content',
      borderRadius: '2px',
      borderColor: 'gray.800',
      bg: 'gray.800',
      px: '8px',
      py: '4px',
      m: 0,
      fontSize: '12px',
      lineHeight: '16px',
      '--popper-arrow-bg': arrowColor,
    };
  },
  variants: {
    transparent: () => {
      return {
        padding: 0,
        bg: 'transparent',
        color: 'black',
      };
    },
    show: () => {
      return {
        zIndex: 99999,
      };
    },
  },
};

export default tooltipTheme;
