/**********************************************
 *** Editor() - CRUD Editor                 ***
 **********************************************
 Note: This is a brand new editor (July 2022) without any inheritence from Owner Accounting project
 */
import React, { useEffect } from 'react';
import {
  Grid,
  GridItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react';
import _get from 'lodash/get';

import { useLayout, BtnDelete } from 'Library/CRUD';

const Editor = (props) => {
  const {
    crud,
    children,
    modalProps,
    modalBodyProps,
    modalContentProps,
    modalHeaderProps,
    modalFooterProps,
    modalOverlayProps,
    max = false,
  } = props;

  //Set Max Height based on Crud Panel Size
  const panelLayout = useLayout((state) => state.crud_panel);
  let modalHeight = _get(panelLayout, 'height', 750) - 100;

  const onClose = () => {
    crud.set({ active: false });
  };

  const onSubmit = () => {
    //ENVOKE CRUD SUBMIT (NOTE: REACT HOOK FORM WILL RELAY TO onSubmit of the provided handleSubmit call)
    crud.requestSubmit();
  };

  const onDelete = async () => {
    await crud.onDelete(crud.key);
  };

  const editorRead = async (crud) => {
    await crud.onRead(crud.key);
  };

  useEffect(() => {
    if (crud && crud.key) {
      editorRead(crud);
    }
    //eslint-override - editorRead Changes crud.record - exhaustive depth
    // eslint-disable-next-line
  }, [crud.key]);

  return (
    <Modal size="6xl" isOpen={crud && crud.active} onClose={onClose} {...modalProps}>
      <ModalOverlay {...modalOverlayProps} />
      <ModalContent {...modalContentProps}>
        <ModalHeader borderTopRadius={'5px'} bg="gray.100" {...modalHeaderProps}>
          {crud.key ? crud.editor.editPrefix || 'Edit' : crud.editor.addPrefix || 'Add'}{' '}
          {crud.editor.title || 'Edit Record'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          minHeight="400px"
          //display='inline-grid' //Important to allow content to size to height 100%
          h="100%"
          {...modalBodyProps}
          overflowY={'auto'}
          {...(max ? { minHeight: modalHeight } : { maxHeight: modalHeight })}
        >
          {children}
        </ModalBody>

        <ModalFooter borderBottomRadius={'5px'} bg="gray.100" {...modalFooterProps}>
          <Grid w="100%" gap="8px" templateColumns={{ base: '1fr', lg: '1fr 150px 150px' }}>
            <GridItem>
              {crud && crud.record && crud.record._allowDelete && (
                <BtnDelete key="editor_confirm-delete" crud={crud} onClick={onDelete} />
              )}
            </GridItem>

            <Button w="100%" colorScheme="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button w="100%" colorScheme="blue" mr={3} onClick={onSubmit}>
              Submit
            </Button>
          </Grid>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default React.memo(Editor);
