import React, { useEffect, useState, useMemo } from 'react';
import { Flex, Box, Spacer } from '@chakra-ui/react';
import _get from 'lodash/get';

import { useStore, useDB } from 'store';
import { Divider, Text, Icon, IconVariant, Select } from 'components';

interface IMerchantNotifications {
  setTabIndex?: (idx: number) => void;
  setOptions: (obj: any) => void;
  user: any;
}

interface INotification {
  count: string | number;
  icon: IconVariant;
  description: string;
}
const Notification = (props: INotification) => {
  const { icon, description, count } = props;

  return (
    <Flex alignItems={'center'} mb={'12px'} w={'100%'}>
      <Icon variant={icon} boxSize={4} mr={'8px'} />
      <Text variant={'labelSmallMedium'} color={'secondary.800'}>
        {description}
      </Text>
      <Spacer />
      <Text variant={'labelSmallBold'}>{count}</Text>
    </Flex>
  );
};

const MerchantNotifications = (props: IMerchantNotifications) => {
  const { user } = props;

  const lookup = useStore((state) => state.lookup);

  const [data, setData] = useState<{ [key: string]: number | string  }>({});
  const [period, setPeriod] = useState('TODAY');

  useEffect(() => {
    const getData = async () => {
      const result = await useDB.getState().axios({
        server: 'PAYGATE',
        method: 'GET',
        url: `/api/v1/dashboard/status?period=${period}&merchant=${user.Merchant}`,
      });
      setData(result.data.data);
    };
    if (user.Merchant && period) getData();
  }, [user.Merchant, period]);

  const options = useMemo(
    () =>
      [
        { label: 'Today', value: 'TODAY' },
        { label: 'Last 7 Days', value: 'LAST7DAYS' },
        { label: 'This Week', value: 'WEEK' },
        { label: 'Last Week', value: 'LASTWEEK' },
        { label: 'This Month', value: 'MONTH' },
        { label: 'Last Month', value: 'LASTMONTH' },
      ].map((o) => (
        <option key={o.value} value={o.value}>
          {o.label}
        </option>
      )),
    [],
  );

  return (
    <Flex
      borderRadius={'4px'}
      border={'1px solid #F2F3F3'}
      bg={'white'}
      p={'24px'}
      flexDir={'column'}
      height={'100%'}
      overflow={'auto'}
    >
      <Flex h={'44px'}>
        <Icon variant={'Notifications'} size={11} />
        <Flex flexDir={'column'} ml={'12px'}>
          <Text variant={'heading05Bold'} color={'secondary.800'} mb={'4px'}>
            Notifications
          </Text>
          <Spacer />
          <Text variant={'labelSmallRegular'} color={'secondary.700'}>
            {_get(lookup, `Merchant[${user.Merchant}].Name`, '')} Summary
          </Text>
        </Flex>
      </Flex>
      <Divider my={'16px'} variant={'fade'} />
      <Flex flexDir={'column'}>
        <Box w={'120px'}>
          <Select
            value={period}
            onChange={(e) => {
              setPeriod(e.target.value);
            }}
            size="sm"
            width={'120px'}
            pr={'0px'}
            sx={{
              backgroundColor: 'secondary.400',
              border: 0,
              cursor: 'pointer',
              borderRadius: '4px',
            }}
          >
            {options}
          </Select>
        </Box>
        <Flex flexDir={'column'} mt={'24px'}>
          <Flex flexDir={'column'} mb={'12px'}>
            <Text variant={'labelSmallBold'} color={'secondary.700'} mb={'12px'}>
              Transactions Status
            </Text>
            <Notification
              description={'Payments Successful'}
              icon={'Paid'}
              count={data?.PaymentsSuccessful || 0}
            />
            <Notification
              description={'Payments Failed'}
              icon={'Failed'}
              count={data?.PaymentsFailed || 0}
            />
            <Box h={'10px'}></Box>
            <Notification
              description={'Refunds Successful'}
              icon={'Refund'}
              count={data?.RefundsSuccessful || 0}
            />
            <Notification
              description={'Refunds Failed'}
              icon={'Failed'}
              count={data?.RefundsFailed || 0}
            />
            <Box h={'10px'}></Box>
            <Notification
              description={'Preauths Successful'}
              icon={'Paid'}
              count={data?.PreauthsSuccessful || 0}
            />
            <Notification
              description={'Preauths Failed'}
              icon={'Failed'}
              count={data?.PreauthsFailed || 0}
            />
            <Box h={'10px'}></Box>
            <Notification
              description={'Claims Successful'}
              icon={'Paid'}
              count={data?.ClaimsSuccessful || 0}
            />
            <Notification
              description={'Claims Failed'}
              icon={'Failed'}
              count={data?.ClaimsFailed || 0}
            />
            <Box h={'10px'}></Box>
            <Notification
              description={'Cancellations Successful'}
              icon={'Paid'}
              count={data?.CancelsSuccessful || 0}
            />
            <Notification
              description={'Cancellations Failed'}
              icon={'Failed'}
              count={data?.CancelsFailed || 0}
            />
          </Flex>
          <Flex flexDir={'column'}>
            <Text variant={'labelSmallBold'} color={'secondary.700'} mb={'12px'}>
              Settlement Status
            </Text>
            <Notification
              description={'Transactions Completed'}
              icon={'Completed'}
              count={data?.Complete || 0}
            />
            <Notification
              description={'Transactions Pending'}
              icon={'Pending'}
              count={data?.Pending || 0}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default React.memo(MerchantNotifications);
