
import React, { ReactElement } from 'react';
import { Modal } from 'components';
import { ModalBody } from '@chakra-ui/react';
import { Worker, Viewer, OpenFile } from '@react-pdf-viewer/core';
import { getFilePlugin } from '@react-pdf-viewer/get-file';
import { defaultLayoutPlugin, ToolbarProps } from '@react-pdf-viewer/default-layout';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import type { ToolbarSlot, TransformToolbarSlot } from '@react-pdf-viewer/toolbar';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';

const packageJson = require('../../../package.json')

interface IReportsPDFModal{
  fileUrl: string,
  fileName: string,
  isOpen: boolean,
  onClose: any,
  title: string
}

const pdfjsVersion = packageJson.dependencies['pdfjs-dist'];

const ReportsPDFModal = (props: IReportsPDFModal) => {
  const {fileUrl, fileName, isOpen, onClose, title} = props;

  const toolbarPluginInstance = toolbarPlugin();
  const { renderDefaultToolbar } = toolbarPluginInstance;

  const transform: TransformToolbarSlot = (slot: ToolbarSlot) => {
    return Object.assign({}, slot, {
      Download: () => (
        <DownloadButton />
      ),
    });
  };


  const getFilePluginInstance = getFilePlugin({
    fileNameGenerator: (file: OpenFile) => {
      return fileName;
    },
  });
  const { DownloadButton } = getFilePluginInstance;

  const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
    <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [],
    renderToolbar,
  });


  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      cancelText={'Close'}
      title={title}
      okProps={{ display: 'none' }}
      height={'1500px'}
      sidebarOverlay={true}
      size={'lg'}
      scrollBehavior={'inside'}
    >
      <ModalBody>
        {fileUrl ? 
        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}>
          <div>
            <Viewer
              fileUrl={fileUrl}
              plugins={[defaultLayoutPluginInstance, getFilePluginInstance, toolbarPluginInstance]}
            />
          </div>
        </Worker>
        :
        'No data to display...'}
      </ModalBody>
    </Modal>
  );
};

export default React.memo(ReportsPDFModal);