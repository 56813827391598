export const DefaultTableStyle = {
  header: {
    style: {
      minHeight: '0px',
      padding: 0,
    },
  },
  head: {
    style: {
      zIndex: 1,
    },
  },
  headRow: {
    style: {
      background: '#F2F3F3',
      border: '1px solid lightgray',
      borderRadius: '5px',
      padding: '0px',
      margin: '0px',
    },
  },
  headCells: {
    style: {
      fontSize: '14px',
      fontWeight: 600,
      color: 'black',
      backgroundColor: '#EDF2F7',
      padding: '4px',
      borderRight: '1px solid lightgray',
    },
  },
  cells: {
    style: {
      paddingLeft: '4px',
      paddingRight: '4px',
      wordBreak: 'break-word',
      borderLeft: '1px solid whitesmoke',
      borderRight: '1px solid whitesmoke',
    },
  },
  rows: {
    denseStyle: {
      minHeight: '30px',
    },
    stripedStyle: {
      backgroundColor: '#FCFCFC',
    },
  },
  expanderRow: {
    style: {
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
  },
};
