/***************************************************
 *** FilterInputRange() - Crud Date Range Filter ***
 ***************************************************
 2022-07-05 Fix problem when input date was changing when reactivating search
 */
import React, { useState, useCallback } from 'react';

import { FormControl, IRangeModifier, DateRange } from 'components';

interface FilterDateRangeProps {
  value: IRangeModifier;
  onChange: (arr: Array<Date>) => void;
}
export const FilterInputDateRange: React.FC<FilterDateRangeProps> = (props) => {
  const { onChange: onChangeProp, value } = props;
  const [date, setDate] = useState<IRangeModifier>(value);

  const onChange = useCallback(
    (newDate: IRangeModifier) => {
      setDate(newDate);
      onChangeProp([newDate.from || new Date(), newDate.to || new Date()]);
    },
    [onChangeProp],
  );

  return (
    <FormControl p={'4px'}>
      <DateRange value={date} onChange={onChange} buttonProps={{ size: 'md', width: '100%' }} />
    </FormControl>
  );
};
