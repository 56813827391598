import React from 'react';
import { Box, BoxProps, useStyles } from '@chakra-ui/react';

export const CardBody: React.FC<BoxProps> = (props) => {
  const { children, ...rest } = props;
  const styles = useStyles();

  return (
    <Box sx={styles.body} {...rest}>
      {children}
    </Box>
  );
};
