import React from 'react';
import {
  ModalHeader as ChakraModalHeader,
  ModalHeaderProps as ChakraModalHeaderProps,
  ModalCloseButton,
  Box,
  HStack,
  CloseButtonProps,
  Text,
  TextProps,
  Spacer,
} from '@chakra-ui/react';

import { Icon } from 'components';

export interface ModalHeaderProps
  extends Omit<ChakraModalHeaderProps, 'children' | 'subTitle' | 'title'> {
  title?: string | React.ReactNode;
  titleProps?: TextProps;
  subTitle?: string | React.ReactNode;
  subTitleProps?: TextProps;
  actions?: Array<React.ReactNode>;
  closeButtonProps?: CloseButtonProps;
  onBack?: () => void;
  ref?: React.LegacyRef<HTMLElement> | undefined;
}

const Modal: React.FC<ModalHeaderProps> = (props) => {
  const {
    title,
    titleProps,
    subTitle,
    subTitleProps,
    actions,
    closeButtonProps,
    onBack,
    ref,
    ...rest
  } = props;

  return (
    <ChakraModalHeader display="flex" alignItems="center" ref={ref} {...rest}>
      <Box display="flex" alignItems="center" overflowX={'hidden'}>
        <Box overflowX={'hidden'} textOverflow={'ellipsis'} whiteSpace={'nowrap'} width={'100%'}>
          {subTitle && (
            <Text fontSize="14px" fontWeight="normal" lineHeight="16px" {...subTitleProps}>
              {subTitle}
            </Text>
          )}
          {title && <>{typeof title === 'string' ? <Text {...titleProps}>{title}</Text> : title}</>}
        </Box>
      </Box>
      <Spacer />
      <HStack spacing="8px">
        {actions && actions.map((action, index) => <Box key={index}>{action}</Box>)}
        <ModalCloseButton width="24px" height="24px" {...closeButtonProps}>
          <Icon variant="Close" size={6} color="#94989B" />
        </ModalCloseButton>
      </HStack>
    </ChakraModalHeader>
  );
};

export default Modal;
