import React from 'react';
import { Image } from '@chakra-ui/image';
import online from '../../assets/icon-transaction-source-online.svg'
import terminal from '../../assets/icon-transaction-source-terminal.svg'

export interface ITSIcon{
  variant: string;
  size?: string;
}

const TransactionSourceIcon: React.FC<ITSIcon> = (props) => {
  const { variant, size = '25px' } = props;
  return <Image
    src={variant === 'online' ? online : terminal}
    boxSize={size}
    alt={variant}
    />;
};

export default TransactionSourceIcon;
