import React from 'react';
import { Flex, useMultiStyleConfig } from '@chakra-ui/react';

import { Button, ButtonProps, Tooltip } from 'components';

interface Props extends ButtonProps {
  tooltipProps: {
    hidden: boolean;
    label?: string;
  };
}

export const SidebarButton: React.FC<Props> = React.forwardRef<HTMLInputElement, Props>((props) => {
  const { children, tooltipProps, ...rest } = props;
  const { hidden, label: tooltipLabelProp } = tooltipProps;
  const tooltipText =
    'You do not have sufficient User Rights to access this function in Guestpoint';
  const tooltipLabel = tooltipLabelProp || tooltipText;

  const styles = useMultiStyleConfig('Sidebar', {});

  return (
    <Tooltip hidden={hidden} label={tooltipLabel} shouldWrapChildren maxWidth={'unset'}>
      <Flex sx={styles.menuItemsContainer}>
        <Button sx={styles.menuItem} {...rest} aria-selected={props.isActive}>
          {children}
        </Button>
      </Flex>
    </Tooltip>
  );
});
