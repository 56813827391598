import { useMemo } from 'react';
import { Box, GridItem } from '@chakra-ui/react';
import { FaFlag } from 'react-icons/fa';

import CRUD, {
  useCRUD,
  Editor,
  Table,
  FilterInputSearch,
  Filters,
  BtnRefresh,
  BtnInactive,
  BtnAddRecord,
  InactiveFlag,
} from 'Library/CRUD';
import { useDB } from 'store';
import { Alert } from 'components';
import { useAlert } from 'utils';

import { ProviderEdit } from './ProviderEdit';

export const Provider = () => {
  const [alert, setAlert] = useAlert();

  // eslint-disable-next-line
  const crud = useCRUD({
    id: 'provider',
    title: 'Providers',
    hasEditor: true,
    keyField: 'code',
    setAlert: setAlert,
    editor: {
      title: 'Provider',
    },
    fetch: async () => {
      const result = await useDB.getState().axios({
        server: 'PAYGATE',
        method: 'GET',
        url: `/api/management/provider/fetch`,
      });
      return result.data;
    },
    create: async (rec: any) => {
      const result = await useDB.getState().axios({
        server: 'PAYGATE',
        method: 'POST',
        url: `/api/management/provider/create`,
        data: rec,
      });
      return result;
    },
    read: async (key: string) => {
      const result = await useDB.getState().axios({
        server: 'PAYGATE',
        method: 'GET',
        url: `/api/management/provider/read/${key}`,
      });
      return result;
    },
    update: async (rec: any) => {
      const result = await useDB.getState().axios({
        server: 'PAYGATE',
        method: 'POST',
        url: `/api/management/provider/create`,
        data: rec,
      });
      return result;
    },
    delete: async (key: string) => {
      const result = await useDB.getState().axios({
        server: 'PAYGATE',
        method: 'DELETE',
        url: `/api/management/provider/delete/${key}`,
      });
      return result;
    },
  });

  let columns = useMemo(
    () => [
      {
        name: <FaFlag />,
        selector: (row: any) => row['inactive'],
        format: (row: any) => <InactiveFlag flag={row.inactive} />,
        width: '25px',
        omit: !crud.inactive,
      },
      {
        name: 'Code',
        selector: (row: any) => row['code'],
        sortable: true,
        width: '110px',
      },
      {
        name: 'Name',
        selector: (row: any) => row['name'],
        sortable: true,
      },
      {
        name: 'Type',
        selector: (row: any) => row['type'],
        sortable: true,
        width: '120px',
      },
    ],
    [crud.inactive],
  );

  const filters = useMemo(() => {
    return (
      <Filters
        templateColumns={['1fr', '1fr 1fr', '1fr 1fr', '1fr 1fr', '400px 1fr 150px 150px 150px']}
      >
        <FilterInputSearch crud={crud} />
        <GridItem />
        <BtnInactive crud={crud} />
        <BtnRefresh crud={crud} />
        <BtnAddRecord crud={crud} />
      </Filters>
    );
    // eslint-disable-next-line
  }, [crud.filter, crud.inactive]);

  return (
    <Box fontFamily={'Roboto Condensed'} h="100%">
      <CRUD id="merchants" crud={crud} filters={filters}>
        <Alert alert={alert} setAlert={setAlert} />
        <Editor crud={crud}>
          <ProviderEdit id="merchant-edit" crud={crud} />
        </Editor>
        <Table
          id="merchant-table"
          crud={crud}
          columns={columns}
          responsive
          paginationContext={true}
        ></Table>
      </CRUD>
    </Box>
  );
};
