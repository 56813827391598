import { Box } from '@chakra-ui/react';

import CRUD, { useCRUD, Editor, Table } from 'Library/CRUD';
import { useDB } from 'store';
import { Alert } from 'components';
import { useAlert } from 'utils';

import { ControlEdit } from './ControlEdit';

export const Control = () => {
  const [alert, setAlert] = useAlert();

  // eslint-disable-next-line
  const crud = useCRUD({
    id: 'control',
    title: 'Control',
    hasEditor: true,
    keyField: 'key',
    setAlert: setAlert,
    editor: {
      title: 'Control Settings',
    },
    editorOnly: true,
    fetch: async () => {},
    create: async () => {},
    read: async () => {
      const result = await useDB.getState().axios({
        server: 'PAYGATE',
        method: 'GET',
        url: `/api/management/control/read`,
      });
      //console.log('READ', result.data)
      return result.data;
    },
    update: async (rec: any) => {
      const result = await useDB.getState().axios({
        server: 'PAYGATE',
        method: 'POST',
        url: `/api/management/control/update`,
        data: rec,
      });
      return result;
    },
    delete: async () => {},
  });

  const columns: any = [];

  return (
    <Box fontFamily={'Roboto Condensed'} h="100%">
      <CRUD id="merchants" crud={crud}>
        <Alert alert={alert} setAlert={setAlert} />
        <Editor crud={crud}>
          <ControlEdit id="merchant-edit" crud={crud} />
        </Editor>
        <Table
          id="merchant-table"
          crud={crud}
          columns={columns}
          responsive
          paginationContext={true}
        ></Table>
      </CRUD>
    </Box>
  );
};
