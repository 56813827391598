//useCRUDStore - USED FOR STORAGE OF FILTERS
//Note: This is persistant store to preserve state accross multiple crud components
import create from 'zustand';
import { persist } from 'zustand/middleware';

export const useCRUDStore = create(
  persist(
    (set, get) => ({
      setState: (id, obj) => {
        set({ [id]: obj });
      },
      clear: (id) => {
        set({ [id]: {} });
      },
    }),
    {
      name: `crud-store`,
      getStorage: () => sessionStorage,
    },
  ),
);
