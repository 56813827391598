import { useMemo } from 'react';
import { Box, GridItem } from '@chakra-ui/react';
import { FaFlag } from 'react-icons/fa';

import CRUD, {
  useCRUD,
  Editor,
  Table,
  FilterInputSearch,
  Filters,
  BtnRefresh,
  BtnInactive,
  BtnAddRecord,
  InactiveFlag,
} from 'Library/CRUD';
import { Alert } from 'components';
import { useAlert } from 'utils';
import { useDB } from 'store';

import { MerchantEdit } from './MerchantEdit';

export const Merchants = () => {
  const [alert, setAlert] = useAlert();

  // eslint-disable-next-line
  const crud = useCRUD({
    id: 'merchants',
    title: 'Merchant Accounts',
    hasEditor: true,
    keyField: 'code',
    setAlert: setAlert,
    editor: {
      title: 'Merchant',
    },
    fetch: async () => {
      const result = await useDB.getState().axios({
        server: 'PAYGATE',
        method: 'GET',
        url: `/api/v1/merchants`,
      });
      return result.data.data;
    },
    create: async (rec: any) => {
      const result = await useDB.getState().axios({
        server: 'PAYGATE',
        method: 'POST',
        url: `/api/v1/merchants`,
        data: rec,
      });
      return result;
    },
    read: async (key: string) => {
      const result = await useDB.getState().axios({
        server: 'PAYGATE',
        method: 'GET',
        url: `/api/v1/merchants/${key}`,
      });
      return result;
    },
    update: async (rec: any) => {
      const result = await useDB.getState().axios({
        server: 'PAYGATE',
        method: 'PUT',
        url: `/api/v1/merchants/${rec}`,
        data: rec,
      });
      return result;
    },
    delete: async (key: string) => {
      const result = await useDB.getState().axios({
        server: 'PAYGATE',
        method: 'DELETE',
        url: `/api/v1/merchants/${key}`,
      });
      return result;
    },
  });

  let columns = useMemo(
    () => [
      {
        name: <FaFlag />,
        selector: (row: any) => row['inactive'],
        format: (row: any) => <InactiveFlag flag={row.inactive} />,
        width: '25px',
        omit: !crud.inactive,
      },
      {
        name: 'Code',
        selector: (row: any) => row['Code'],
        sortable: true,
        width: '110px',
      },
      {
        name: 'Name',
        selector: (row: any) => row['Name'],
        sortable: true,
      },
      {
        name: 'Provider',
        selector: (row: any) => row['ProviderCode'],
        sortable: true,
        width: '120px',
      },
      {
        name: 'Provider Account',
        selector: (row: any) => row['merchant_account'],
        sortable: true,
        //width: '300px',
      },
      {
        name: 'Rate Plan',
        selector: (row: any) => row['Rateplan'],
        sortable: true,
        width: '130px',
      },
    ],
    [crud.inactive],
  );

  const filters = useMemo(() => {
    return (
      <Filters
        templateColumns={['1fr', '1fr 1fr', '1fr 1fr', '1fr 1fr', '400px 1fr 150px 150px 150px']}
      >
        <FilterInputSearch crud={crud} />
        <GridItem />
        <BtnInactive crud={crud} />
        <BtnRefresh crud={crud} />
        <BtnAddRecord crud={crud} />
      </Filters>
    );
    // eslint-disable-next-line
  }, [crud.filter, crud.inactive]);

  return (
    <Box fontFamily={'Roboto Condensed'} h="100%">
      <CRUD id="merchants" crud={crud} filters={filters}>
        <Alert alert={alert} setAlert={setAlert} />
        <Editor crud={crud}>
          <MerchantEdit id="merchant-edit" crud={crud} />
        </Editor>
        <Table
          id="merchant-table"
          crud={crud}
          columns={columns}
          responsive
          paginationContext={true}
        ></Table>
      </CRUD>
    </Box>
  );
};
