import { SystemStyleObject, ThemeThunk } from '@chakra-ui/styled-system';

interface FormErrorTheme {
  baseStyle: ThemeThunk<SystemStyleObject>;
}

const formErrorTheme: FormErrorTheme = {
  baseStyle: {
    text: {
      color: 'red.500',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '16px',
    },
  },
};

export default formErrorTheme;
