import { useEffect } from 'react';

import { useAuth, useDB } from 'store';

export const useHandleAuth = (): string => {
  const authToken = useAuth((state) => state.user?.token);
  const authLogout = useAuth((state) => state.logout);
  const dbLogout = useDB((state) => state.logout);

  useEffect(() => {
    useDB.getState().setToken(authToken || '');
    useAuth.getState().setLogout(false);
  }, [authToken]);

  useEffect(() => {
    // manual logout or logout via DB in case of 403 errors
    if (authLogout || dbLogout) {
      useDB.getState().setToken('');
      useAuth.getState().setUser({});
    }
  }, [authLogout, dbLogout]);

  return authToken || '';
};
