import React, { useEffect, useMemo, useCallback } from 'react';
import moment from 'moment';
import { Box, Grid, GridItem, Flex, Text } from '@chakra-ui/react';
import _get from 'lodash/get';

import CRUD, { useCRUD, Table, NumberFormat } from 'Library/CRUD';
import { Alert } from 'components';
import { useAlert } from 'utils';
import { useDB, useStore } from 'store';

interface ISettlementsItemDrilldown {
  data: any;
  parent: any;
  refreshParent: any;
  applyFees: (id: string) => {};
}

const SettlementsItemDrilldown = (props: ISettlementsItemDrilldown) => {
  const { data, parent, refreshParent, applyFees, ...o } = props;

  const [alert, setAlert] = useAlert();

  //FACILITATE CHILD REFRESH FROM PARENT
  let childRefresh = _get(props, 'filter._childRefresh', null);
  useEffect(() => {
    if (crudFiltered && crudFiltered.refresh)
      crudFiltered.refresh({ message: 'Settlements Drilldown Child Refresh' });
    // eslint-disable-next-line
  }, [childRefresh]);

  const lookup = useStore((state) => state.lookup);
  const propertyID = Object.keys(lookup.Merchant)?.[0];
  const timezone = lookup.Merchant?.[propertyID as any]?.Timezone;

  const crudFiltered = useCRUD({
    id: 'settlementsitemdrilldown_filtered',
    hasEditor: false,
    keyField: 'id',
    setAlert: setAlert,
    drilldown: true,
    filter: {
      startDate: moment().subtract(2, 'days').toDate(),
      endDate: new Date(),
    },
    fetch: async () => {
      const result = await useDB.getState().axios({
        server: 'PAYGATE',
        method: 'GET',
        url: `/api/v1/transactions/settlement/${data.SettlementID}`,
      });
      const arr = result.data.data
        .map((v: any) => ({
          ...v,
          Date: timezone
            ? moment.utc(v.Date).tz(timezone).format('DD/MM/YYYY')
            : moment(v.Date).format('DD/MM/YYYY'),
        }))
        .filter((v: any) => getCardType(v.CardType) === getCardType(data.CardType));

      return arr.length > 0
        ? arr
        : [
            {
              Date: null,
              CardType: null,
              TransactionType: null,
              ProviderReference: null,
              TransactionMode: null,
              Reason: null,
              ReservationNumber: null,
              SettledAmount: null,
            },
          ];
    },
  });

  const getCardType = useCallback((cardType: string) => {
    switch (cardType) {
      case 'AX':
      case 'AMEX':
        return 'American Express';
      case 'VI':
      case 'VISA':
        return 'Visa';
      case 'MC':
      case 'MCARD':
        return 'Mastercard';
      default:
        return 'Other';
    }
  }, []);

  const columns = useMemo(
    () => [
      {
        name: 'Date',
        selector: (row: any) => row['Date'] ?? '-',
        width: '105px',
        sortable: true,
      },

      {
        name: 'Payment Type',
        selector: (row: any) => (row['CardType'] ? getCardType(row['CardType']) : '-'),
        width: '130px',
        sortable: true,
      },
      {
        name: 'Type',
        selector: (row: any) => row['TransactionType'] ?? '-',
        width: '210px',
        sortable: true,
      },
      {
        name: 'Tran Ref',
        selector: (row: any) => row['ProviderReference'] ?? '-',
        sortable: true,
        width: '100px',
      },
      {
        name: 'Tran Mode',
        selector: (row: any) => row['TransactionMode'] ?? '-',
        sortable: true,
        width: '100px',
      },
      {
        name: 'Description',
        selector: (row: any) => row['Reason'] ?? '-',
        width: '185px',
        sortable: true,
      },
      {
        name: 'Reservation #',
        selector: (row: any) => row['ReservationNumber'] ?? '-',
        width: '140px',
        sortable: true,
      },
      {
        name: 'Amount',
        selector: (row: any) => row['SettledAmount'] ?? '-',
        width: '85px',
        sortable: true,
        format: (row: any) => (
          <>
            {row.SettledAmount !== '-' && row.SettledAmount !== null ? (
              <NumberFormat
                amount={row.SettledAmount}
                color={row.TransactionType === 'REFUND' ? 'red' : 'green'}
              />
            ) : (
              '-'
            )}
          </>
        ),
      },
    ],
    [getCardType],
  );

  const conditionalRowStyles = [
    {
      when: (row: any) =>
        row.type === 'PAYMENT' && (row.status === 'VERIFIED' || row.status === 'COMPLETE'),
      style: {
        color: 'green',
      },
    },
    {
      when: (row: any) =>
        row.type === 'PREAUTH' && (row.status === 'VERIFIED' || row.status === 'COMPLETE'),
      style: {
        color: 'blue',
      },
    },
    {
      when: (row: any) =>
        row.type === 'REFUND' && (row.status === 'VERIFIED' || row.status === 'COMPLETE'),
      style: {
        color: 'darkorange',
      },
    },
    {
      when: (row: any) => row.status === 'ERROR',
      style: {
        color: 'red',
      },
    },
  ];

  return (
    <Box fontFamily={'Roboto Condensed'} h="100%" ml={'45px'}>
      <CRUD id="settlementsitemdrilldown_filtered" crud={crudFiltered} {...o}>
        <Alert alert={alert} setAlert={setAlert} />
        <Grid templateColumns="1fr 150px">
          <GridItem>
            <Flex mt={2} direction="row" fontSize={14}>
              <Text mr={1}>{`${getCardType(data.CardType)} Transactions`}</Text>
            </Flex>
          </GridItem>
        </Grid>
        <Table
          id="settlements_item_drilldown_filtered"
          crud={crudFiltered}
          columns={columns}
          responsive
          paginationContext={true}
          conditionalRowStyles={conditionalRowStyles}
        />
      </CRUD>
    </Box>
  );
};

export default React.memo(SettlementsItemDrilldown);
