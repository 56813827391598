import React, { useCallback, useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Box } from '@chakra-ui/react';
import AutoSizer from 'react-virtualized-auto-sizer';
import moment from 'moment';

import { useDB, useAuth } from 'store';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options: any = {
  scales: {
    //y: { ticks: { mirror: true } },
    yAxes: [{ ticks: { mirror: true } }],
  },
  maintainAspectRatio: false,
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 1,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
};

const RevenueChart = () => {
  const merchant = useAuth((state) => state.user?.Merchant);

  const [chartData, setChartData] = useState<any>();

  const getData = useCallback(async () => {
    const result = await useDB.getState().axios({
      server: 'PAYGATE',
      method: 'GET',
      url: `/api/v1/dashboard/revenuechart?merchant=${merchant}`,
    });
    //setChartData(result.data)
    const labels = [];
    const amounts = [];

    let lastDate = moment();

    for (let i = 0; i < result.data.data.length; i++) {
      let rec = result.data.data[i];
      lastDate = moment()
        .year(rec.Year)
        .month(rec.Month - 1)
        .endOf('month');
      labels.push(lastDate.format('MMMM YYYY'));
      amounts.push(parseFloat(rec.Amount));
    }

    // Add Extra records to make 12 months
    for (let i = result.data.data.length; i < 12; i++) {
      lastDate.subtract(1, 'month');
      labels.push(lastDate.format('MMMM YYYY'));
      amounts.push(0);
    }

    const dt = {
      labels: labels,
      datasets: [
        {
          label: '',
          data: amounts,
          borderColor: 'lightgray',
          backgroundColor: '#bae1ff',
        },
      ],
      detail: {},
    };

    setChartData(dt);
  }, [merchant]);

  useEffect(() => {
    if (merchant) getData();
  }, [merchant, getData]);

  if (!chartData || !Object.keys(chartData).length) {
    return <></>;
  }

  return (
    <AutoSizer>
      {({ width, height }: { width: number; height: number }) => (
        <Box fontFamily={'Roboto Condensed'} width={width} height={height}>
          <Bar options={options} data={chartData} />
        </Box>
      )}
    </AutoSizer>
  );
};

export default React.memo(RevenueChart);
